import { Component, Input } from "@angular/core";
import { IOrganization, OrganizationType } from "../../interfaces/api-models";
import { BaseComponent } from "./base.component";

@Component({
    selector: "organization-summary",
    templateUrl: "./organization-summary.component.html",
    styleUrls: ["./organization-summary.component.scss"],
})
export class OrganizationSummaryComponent extends BaseComponent {
    @Input() organization!: IOrganization;

    OrganizationType = OrganizationType;

    private statuses = [
        { badge: "secondary", label: "OK" },
        { badge: "warning", label: "Down" },
        { badge: "danger", label: "Error" },
        { badge: "warn", label: "Unknown" },
        { badge: "info", label: "Ready" }];

    get canSendRequest() {
        return this.organization.type === OrganizationType.FarmSoftware;
    }
    
    get badgeColor() {
        switch (this.organization.type) {
            case OrganizationType.MilkRecordingOrganization:
                return "primary";
            case OrganizationType.CloudProvider:
                return "warn";
            case OrganizationType.FarmSoftware:
                return "secondary";
            default:
                return "danger";
        }
    }
    
    get allLogCount() {
        return this.canSendRequest ? this.organization.allRequestCount : this.organization.allResponseCount;
    }
    
    get succeededLogCount() {
        return this.canSendRequest ? this.organization.allSuccessRequestCount : this.organization.allSuccessResponseCount;
    }

    get failedLogCount() {
        return this.canSendRequest ? this.organization.allFailedRequestCount : this.organization.allFailedResponseCount;
    }

    get serviceStatus() {
        return this.organization.status;  // TODO fix after status is implemented
    }

    get serviceStatusBadgeColor() {
        const status = this.statuses.find(x => x.label === this.organization.status);
        return status != null ? status.badge : "";  // TODO fix after status is implemented
    }

    get iddenStatus() {
        return this.organization.status;  // TODO fix after status is implemented
    }

    get iddenStatusBadgeColor() {
        const status = this.statuses.find(x => x.label === this.organization.status);
        return status != null ? status.badge : ""; // TODO fix after status is implemented
    }

    get transactionTime() {
        return '0.00'; // TODO fix after status is implemented
    }
}
