<div class="container">

    <mat-form-field appearance="fill">
        <mat-label>Select report</mat-label>
        <mat-select 
            [(ngModel)]="selectedReportType" 
            placeholder="Select report" 
            name="report"
        >    
            <mat-option *ngFor="let option of reportTypes" [value]="option.key">{{option.value}}</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="report-container">
        <mat-card *ngIf="selectedReportType != null">
            <mat-card-title>{{getReportTitle()}}</mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content>
                <app-organizations-requests-report 
                    *ngIf="selectedReportType == ReportType.OrganizationsRequestByPeriod">
                </app-organizations-requests-report>
                <app-organization-messages-report 
                    *ngIf="selectedReportType == ReportType.OrganizationMessagesByPeriod">
                </app-organization-messages-report>
                <app-organization-locations-report 
                    *ngIf="selectedReportType == ReportType.OrganizationLocationsByPeriod">
                </app-organization-locations-report>
            </mat-card-content>
        </mat-card>
    </div>
</div>