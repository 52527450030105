<header>
    <mat-toolbar>
        <span class="logo" mat-button [routerLink]="['/']"></span>
        <span class="spacer"></span>
        <ng-container *ngIf="isAuthenticated | async">
	        <a mat-button [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/status/overall']">Status</a>
	        <a mat-button [routerLinkActive]="['link-active']" [routerLink]="['/statistics']">Statistics</a>
            <a mat-button [routerLinkActive]="['link-active']" [routerLink]="['/logs']">Logs</a>
	        <a *ngIf="isSystemAdmin" mat-button [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/organization-management']">Organization management</a>
            <a *ngIf="isAdmin" mat-button [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/translation-management']">Translation management</a>
            <a *ngIf="isSystemAdmin" mat-button [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/language-library']">Language library</a>
	        <a *ngIf="isAdmin" mat-button [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/user-management']">User management</a>
        </ng-container>
        <ng-container *ngIf="user">
            <a mat-button [routerLinkActive]="['link-active']" [routerLink]='["/user-management", user!.sub]' title="Manage">Profile</a>
            <a mat-button [routerLink]='["/authentication/logout"]' [state]="{ local: true }" title="Logout">Logout</a>
        </ng-container>
        <ng-container *ngIf="!(isAuthenticated | async)">
            <a mat-button [routerLink]='["/authentication/login"]'>Login</a>
        </ng-container>
    </mat-toolbar>
</header>
