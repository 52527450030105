<div class="container">
	
	<button mat-raised-button color="primary" (click)="openAddNewUserModal()">{{resources.Account.AddUser}}</button>

    <mat-paginator [pageSizeOptions]="[10, 30, 50]" (page)="pageChanged($event)"  showFirstLastButtons></mat-paginator>
    <table mat-table matSort multiTemplateDataRows [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- columns -->
        <ng-container matColumnDef="expandedOrganizations">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>expandedOrganizations</th>
            <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">
                <div class="expanded-content" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="organization-container">
                        <div *ngFor="let organization of element.organizationModels" class="organization-item">
                            <div>{{organization.name}}</div>
                            <div>{{formatOrganizationType(organization.type)}}</div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>
        
        <ng-container matColumnDef="organizationCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.Account.Organizations}}</th>
            <td mat-cell *matCellDef="let user">
                <div *ngIf="user.organizationModels.length > 0">
                    <div>{{user.organizationModels[0].name}}</div>
                    <span *ngIf="user.organizationModels.length > 1" class="badge primary">+{{user.organizationModels.length-1}}</span>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.Account.Email}}</th>
            <td mat-cell *matCellDef="let user"><a mat-button color="primary" [routerLink]="user.id">{{user.email}}</a></td>
        </ng-container>

        <ng-container matColumnDef="roles">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.Account.Role}}</th>
            <td mat-cell *matCellDef="let user">{{formatRoles(user)}}</td>
        </ng-container>

        <ng-container matColumnDef="buttons">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let user"></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns" class="element-row" [class.expanded-row]="expandedElement === row"
        (click)="expandedElement = expandedElement === row ? null : row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedOrganizations']" class="detail-row"></tr>
    </table>
</div>
