<h2 mat-dialog-title>{{resources.Account.AddOrganization}}</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="form" autocomplete="off">
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Organization.Name}}</mat-label>
            <input matInput [(ngModel)]="newOrganization.name" formControlName="name" required>
            <mat-icon matSuffix></mat-icon>
            <mat-error *ngIf="!isFieldValid('name')">{{getErrorMessage('name')}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Organization.Type}}</mat-label>
            <mat-icon matSuffix></mat-icon>
            <mat-select #orgTypeSelect (selectionChange)="orgTypeChanged(orgTypeSelect.value)" placeholder="{{resources.Account.OrganizationTypeSelect}}" formControlName="orgType" required>
                <mat-option *ngFor="let orgType of organizationTypes" [value]="orgType">{{ orgType.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        <button type="button" mat-raised-button color="primary" (click)="openManageOrganizationTypeModal()">{{resources.Account.ManageOrganizationType}}</button>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Organization.EmailType}}</mat-label>
            <mat-icon matSuffix></mat-icon>
            <mat-select [required]="isEmailTypeRequired" #emailTypeSelect formControlName="emailType" placeholder="{{resources.Account.EmailTypeSelect}}" [(value)]="selectedEmailType">
                <mat-option *ngFor="let type of emailTypes" [value]="type">{{ type.value }}</mat-option>
            </mat-select>
            <mat-error *ngIf="isEmailTypeRequired">{{getErrorMessage('emailType')}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Account.Email}}</mat-label>
            <div class="input-emails">
                <input matInput [(ngModel)]="emailToAdd" [required]="isEmailRequired" formControlName="email">
            </div>
            <mat-error *ngIf="!isFieldValid('email')">{{getErrorMessage('email')}}</mat-error>
        </mat-form-field>
        <button  class="email-button" type="button" mat-raised-button color="primary" [disabled]="isEmailAddingDisabled" cdkFocusInitial (click)="addEmail(emailToAdd)">
            <mat-icon matSuffix>email</mat-icon>
            {{resources.Common.Add}} {{resources.Account.Email}}
        </button>
        <mat-form-field class="email-list" *ngIf="this.newOrganization.emailInformations != null && this.newOrganization.emailInformations.length > 0">
            <mat-label>Existing emails</mat-label>
            <mat-chip-list [multiple]="true">
                <mat-chip *ngFor="let emailInfo of this.newOrganization.emailInformations" [color]="undefined" class="existing-emails"
                          removable (removed)="removeEmail(emailInfo.email)">
                          <div class="email-info">
                            <span class="label">{{emailInfo.type}}</span>
                            <span>{{emailInfo.email}}</span>
                          </div>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
            <mat-icon matSuffix></mat-icon>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Organization.StreetAddress}}</mat-label>
            <input matInput [(ngModel)]="newOrganization.streetAddress" formControlName="streetAddress" required>
            <mat-icon matSuffix></mat-icon>
            <mat-error *ngIf="!isFieldValid('streetAddress')">{{getErrorMessage('streetAddress')}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Organization.ZipCode}}</mat-label>
            <input matInput [(ngModel)]="newOrganization.zipCode" formControlName="zipCode" required>
            <mat-icon matSuffix></mat-icon>
            <mat-error *ngIf="!isFieldValid('zipCode')">{{getErrorMessage('zipCode')}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Organization.City}}</mat-label>
            <input matInput [(ngModel)]="newOrganization.city" formControlName="city" required>
            <mat-icon matSuffix></mat-icon>
            <mat-error *ngIf="!isFieldValid('city')">{{getErrorMessage('city')}}</mat-error>
        </mat-form-field>
        <br>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Organization.Region}}</mat-label>
            <input matInput [(ngModel)]="newOrganization.region" formControlName="region" required>
            <mat-icon matSuffix></mat-icon>
            <mat-error *ngIf="!isFieldValid('region')">{{getErrorMessage('region')}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Organization.Country}}</mat-label>
            <input matInput [(ngModel)]="newOrganization.country" formControlName="country" required>
            <mat-icon matSuffix></mat-icon>
            <mat-error *ngIf="!isFieldValid('country')">{{getErrorMessage('country')}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Organization.State}}</mat-label>
            <input matInput [(ngModel)]="newOrganization.state" formControlName="state">
            <mat-icon matSuffix></mat-icon>
            <mat-error *ngIf="!isFieldValid('state')">{{getErrorMessage('state')}}</mat-error>
        </mat-form-field>
        <br>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Organization.AuthenticationUrl}}</mat-label>
            <input matInput [(ngModel)]="newOrganization.authenticationUrl" formControlName="authenticationUrl">
            <mat-icon matSuffix></mat-icon>
            <mat-error *ngIf="!isFieldValid('authenticationUrl')">{{getErrorMessage('authenticationUrl')}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Organization.ServiceUrl}}</mat-label>
            <input matInput [(ngModel)]="newOrganization.serviceUrl" formControlName="serviceUrl">
            <mat-icon matSuffix></mat-icon>
            <mat-error *ngIf="!isFieldValid('serviceUrl')">{{getErrorMessage('serviceUrl')}}</mat-error>
        </mat-form-field>
        <br>
        <label>
            {{resources.Organization.CanSendRequest}}:
            <input type="checkbox" [checked]="newOrganization.canSendRequest" (change)="toggleCheckboxValue('canSendRequest')">
        </label>
        <br>
        <label>
            {{resources.Organization.CanReceiveRequest}}:
            <input type="checkbox" [checked]="newOrganization.canReceiveRequest" (change)="toggleCheckboxValue('canReceiveRequest')">
        </label>
        <br>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{resources.Common.Cancel}}</button>
    <button mat-button [disabled]="form.invalid" cdkFocusInitial (click)="addNewOrganization()">{{resources.Common.Add}}</button>
</mat-dialog-actions>
