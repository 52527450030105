import { ScrollingModule } from "@angular/cdk/scrolling";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MessageBoxModule } from "common.module/components/message-box";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { NgLetModule } from "@ngrx-utils/store";
import { ApiAuthorizationModule } from "../api-authorization/api-authorization.module";
import { AuthorizeGuard } from "../api-authorization/authorize.guard";
import { AuthorizeInterceptor } from "../api-authorization/authorize.interceptor";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components";
import { ForbiddenErrorComponent } from "./components/forbidden-error.component";
import { NotificationComponent } from "./components/notification.component";
import { OrganizationStatusComponent } from "./components/organization-status.component";
import { OverallStatusComponent } from "./components/overall.component";
import { HomeComponent } from "./home/home.component";
import { LogDetailComponent } from "./logs/log-detail.component";
import { LogsApiService } from "./logs/logs.api.service";
import { LogsComponent } from "./logs/logs.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { AddNewOrganizationComponent } from "./organization-management/add-new-organization.component";
import { OrganizationDetailComponent } from "./organization-management/organization-detail.component";
import { OrganizationManagementComponent } from "./organization-management/organization-management.component";
import { ManageOrganizationTypeComponent } from "./organization-management/manage-organization-type.component";
import { Resources } from "./resources/resources";
import { AdminApiService } from "./services/admin.api.service";
import { ApiService } from "./services/api.service";
import { LocalUserSettingsService } from "./services/local-user-settings.service";
import { LogGuard } from "./services/guards/log.guard";
import { OrganizationGuard } from "./services/guards/organization.guard";
import { OnlySystemAdminGuard } from "./services/guards/only-system-admin.guard";
import { ModalService } from "./services/modal.service";
import { NotificationService } from "./services/notification.service";
import { OrganizationsApiService } from "./services/organizations.api.service";
import { TranslationApiService } from "./services/translation.api.service"
import { ParameterApiService } from "./services/parameter.api.service";
import { SameOriginInterceptor } from "./services/same-origin.interceptor";
import { UserAccessService } from "./services/user-access.service";
import { StatisticsApiService } from "./services/statistics.api.service";
import { AddNewUserComponent } from "./user-management/add-new-user.component";
import { AddNewLanguageComponent } from "./translation-management/add-new-language.component";
import { AddLanguageLibraryComponent } from "./translation-management/add-language-library.component";
import { UserDetailComponent } from "./user-management/user-detail.component";
import { UserManagementComponent } from "./user-management/user-management.component";
import { StatisticsComponent } from "./statistic/statistics.component";
import { DateUtils } from "./statistic/date-utils";
import { ReportService } from "./statistic/report.service";
import { LoadingInterceptor } from "./services/loading.interceptor";
import { HighchartsChartModule } from "highcharts-angular";
import { DatePipe } from "@angular/common";

import { OrganizationLocationsReportComponent } from "./statistic/reports/organization-locations/organization-locations-report.component";
import { OrganizationMessagesReportComponent } from "./statistic/reports/organization-messages/organization-messages-report.component";
import { OrganizationsRequestsReportComponent } from "./statistic/reports/organizations-request/organizations-requests-report.component";
import { TranslationManagementComponent } from "./translation-management/translation-management.component";
import { LanguageLibraryComponent } from "./translation-management/language-library.component";
import { CopyLanguageToLibraryomponent } from "./translation-management/copy-language-to-library.component";
import { SendEmailComponenet } from "./organization-management/send-email.component";
import { AngularEditorModule } from "@kolkov/angular-editor";

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        LogsComponent,
        LogDetailComponent,
        OrganizationManagementComponent,
        TranslationManagementComponent,
        LanguageLibraryComponent,
        AddNewLanguageComponent,
        AddLanguageLibraryComponent,
        CopyLanguageToLibraryomponent,
        UserManagementComponent,
        AddNewUserComponent,
        AddNewOrganizationComponent,
        SendEmailComponenet,
        ManageOrganizationTypeComponent,
        OrganizationDetailComponent,
        NotificationComponent,
        UserDetailComponent,
        ForbiddenErrorComponent,
        StatisticsComponent,
        OrganizationsRequestsReportComponent,
        OrganizationMessagesReportComponent,
        OrganizationLocationsReportComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
        HttpClientModule,
        FormsModule,
        MatDatepickerModule,
        ApiAuthorizationModule,
        ComponentsModule,
        ReactiveFormsModule,
        MatChipsModule,
        HighchartsChartModule,
        ScrollingModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatNativeDateModule,
        MatCardModule,
        MatSelectModule,
        MatDividerModule,
        MatSnackBarModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatTabsModule,
        MatToolbarModule,
        MatExpansionModule,
        MatTooltipModule,
        MessageBoxModule,
        NgLetModule,
        BrowserAnimationsModule,
        AngularEditorModule,
        RouterModule.forRoot([
            {
                path: "",
                canActivate: [AuthorizeGuard],
                children: [
                    { path: "", redirectTo: "status", pathMatch: "full" },
                    {
                        path: "status",
                        component: HomeComponent,
                        children: [
                            { path: "", redirectTo: "overall", pathMatch: "full"},
                            { path: "overall", component: OverallStatusComponent },
                            { path: "organization/:organizationId", component: OrganizationStatusComponent, canActivate: [OrganizationGuard] }
                        ]
                    },
                    { path: "logs", component: LogsComponent },
                    { path: "user-management", component: UserManagementComponent },
                    { path: "statistics", component: StatisticsComponent },
                    { path: "user-management/:userId", component: UserDetailComponent },
                    { path: "translation-management", component: TranslationManagementComponent },
                    { path: "language-library", component: LanguageLibraryComponent },
                    { path: "organization-management", component: OrganizationManagementComponent },
                    { path: "organization-management/:organizationId", component: OrganizationDetailComponent },
                    { path: "logs/:logId", component: LogDetailComponent, canActivate: [LogGuard] },
                    { path: "forbidden", component: ForbiddenErrorComponent }
                ]
            }
        ],
        { onSameUrlNavigation: "reload" }
    )
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: SameOriginInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
        ApiService,
        LogsApiService,
        OrganizationsApiService,
        TranslationApiService,
        ParameterApiService,
        AdminApiService,
        ModalService,
        UserAccessService,
        NotificationService,
        StatisticsApiService,
        Resources,
        LocalUserSettingsService,
        DatePipe,
        DateUtils,
        ReportService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
