import { Component } from "@angular/core";
import { FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { AngularEditorConfig } from "@kolkov/angular-editor";

import { IOrganization, IOrganizationEmailModel } from "../../interfaces/api-models";
import { BaseComponent } from "../components/base.component";
import { AdminApiService } from "../services/admin.api.service";
import { NotificationService } from "../services/notification.service";
import { OrganizationsApiService } from "app/services/organizations.api.service";


@Component({
    selector: "send-email",
    styleUrls: ["send-email.component.scss"],
    templateUrl: "./send-email.component.html",
})
export class SendEmailComponenet extends BaseComponent {
    emailContent = { subject: "iDDEN information message" } as IOrganizationEmailModel;
    organizations: IOrganization[] = [];
    selectedOrganizations: IOrganization[] = [];
    isLoading = false;
    selectAllOption = { name: "All Organizations selected" };

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter your message here...',
        defaultParagraphSeparator: '',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'}
        ],
        uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
          ['link', 'unlink', 'strikeThrough', 'subscript', 'superscript', 'insertImage', 'insertVideo', 'backgroundColorPicker-']
        ]
    };

    form: FormGroup = this.formBuilder.group({
        subject: ["", [Validators.required, Validators.maxLength(200)]],
        message: ["", [Validators.required, Validators.maxLength(2000)]],
        organization: [null, [Validators.required]],
    });

    constructor(
        private readonly adminApiService: AdminApiService,
        private formBuilder: FormBuilder,
        private notificationService: NotificationService,
        private modal: MatDialogRef<SendEmailComponenet>,
        private readonly organizationService: OrganizationsApiService) {
        super();
    }

    get isSelectedAllOptionSelected(): boolean {
        return this.emailContent.sendToAllOrganization ?? false;
    }

    async sendEmails() {
        this.isLoading = true;
        this.emailContent.organizationIds = this.selectedOrganizations.map(x => x.id);

        const result = await this.adminApiService.sendEmailToOrganizations(this.emailContent);

        if (result?.succeeded) {
            this.notificationService.showNotification([this.resources.Account.EmailSent], true);
            this.modal.close(true);
        } else {
            this.notificationService.showNotification(result?.errors, false);
        }
        this.isLoading = false;
    }

    isFieldValid(fieldName: string) {
        const fieldControl = this.form.get(fieldName);

        if (fieldControl) {
            return fieldControl.valid;
        }

        return false;
    }

    getErrorMessage(fieldName: string) {
        const fieldControl = this.form.get(fieldName);
        if (fieldControl) {

            if (fieldControl.hasError("required")) {
                return this.resources.Error.Required;
            }

            if (fieldControl.hasError("minlength") || fieldControl.hasError("maxlength")) {
                if (fieldName === "subject") {
                    return this.resources.Error.SubjectLength;
                }
                if (fieldName === "message") {
                    return this.resources.Error.MessageLength;
                }
            }
        }

        return "";
    }

    formatOrgatization(organization: IOrganization) {
        return organization?.name ?? "";
    }

    async onKeyUp(keyboardEvent: any) {
        const query = keyboardEvent.target.value;

        if (query == null || query.length < 3) {
            this.organizations = [];
        } else {
            this.organizations = await this.organizationService.findByName(query) ?? [];
        }
    }

    onOrganizationSelected(event: MatAutocompleteSelectedEvent) {
        const organizationToAdd = event.option.value as IOrganization;
    
        if (this.isSelectAllOption(organizationToAdd)) {
            this.emailContent.sendToAllOrganization = true;
            this.selectedOrganizations = [this.selectAllOption as IOrganization];
        } else {
            this.addOrganizationIfNotExists(organizationToAdd);
        }
    
        this.form.controls["organization"].setValue(true);
    }
    
    private isSelectAllOption(organization: IOrganization): boolean {
        return organization === this.selectAllOption;
    }
    
    private addOrganizationIfNotExists(organizationToAdd: IOrganization): void {
        if (!this.emailContent.sendToAllOrganization) {
            const organizationIndex = this.selectedOrganizations.findIndex(
                x => x.identifier === organizationToAdd.identifier
            );
    
            if (organizationIndex === -1) {
                this.selectedOrganizations.push(organizationToAdd);
            }
        }
    }    

    removeOrganization(organization: IOrganization) {
        const index = this.selectedOrganizations.findIndex(x => x.identifier === organization.identifier);

        if (index > -1) {
            this.selectedOrganizations.splice(index, 1);
        }

        if (this.selectedOrganizations.length  === 0) {
            this.form.controls["organization"].setValue(null);
            this.emailContent.sendToAllOrganization = false;
        }
    }
}