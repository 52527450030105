<div class="container">
    <button mat-raised-button *ngIf="isSystemAdmin" (click)="syncAllLanguages()">{{resources.Translation.SyncAllLanguages}}</button>
    <mat-paginator [pageSizeOptions]="[10, 30, 50]" (page)="pageChanged($event)"  showFirstLastButtons></mat-paginator>
    <table mat-table matSort multiTemplateDataRows [dataSource]="dataSource" matSortActive="createdAt" matSortDisableClear matSortDirection="desc" class="mat-elevation-z8">

        <!-- columns -->
        <ng-container matColumnDef="expandedLanguages">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>expandedLanguages</th>
            <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">
                <div class="expanded-content" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="translation-container" *ngIf="!isLoading">
                        <div *ngFor="let language of element.languages" class="translation-item">
                            <div><button mat-raised-button (click)="openEditLanguageLibraryModal(language, element)">{{language.displayName}}</button></div>
                            <div class="sync-btn" *ngIf="isSystemAdmin">
                                <button mat-icon-button color="primary" (click)="syncToADE(language, element.name)"
                                    [matTooltip]="language.lastSyncedAt ? resources.Translation.LastSynced + ': ' + (language.lastSyncedAt | date:'medium') : resources.Translation.SyncLanguage">
                                    <mat-icon>autorenew</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>
        
        <ng-container matColumnDef="languages">
            <th mat-header-cell *matHeaderCellDef>{{resources.Translation.Languages}}</th>
            <td mat-cell *matCellDef="let org">
                <div *ngIf="!isLoading && org.languages.length > 0">
                    <div class="display-name">{{org.languages[0].displayName}}</div>
                    <span *ngIf="org.languages.length > 1" class="badge primary">+{{org.languages.length-1}}</span>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.Account.Organizations}}</th>
            <td mat-cell *matCellDef="let org"><span *ngIf="!isLoading">{{org.name}}</span></td>
        </ng-container>

        <ng-container matColumnDef="buttons">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button mat-raised-button color="primary" (click)="openAddNewLanguageModal(row);expandedElement = null">{{resources.Translation.CreateLanguage}}</button>
                <button mat-raised-button (click)="openAddLanguageLibraryModal(row); expandedElement = null">{{resources.Translation.ManageLanguages}}</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns" class="element-row" 
            [class.expanded-row]="expandedElement === row"
            (click)="expandedElement = expandedElement === row ? null : row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedLanguages']" class="detail-row"></tr>
    </table>
</div>