import { DateRangeFilter, FilterCollection, ValueFilter, MultiValueFilter } from "common.module/filters";
import { MultiPropertyFilter } from "../filters/multi-property-filter";
import { ILog } from "../../interfaces/api-models";

export class LogsFilters extends FilterCollection<ILog> {
    date = new DateRangeFilter<ILog>("createdAt");
    locationId = new ValueFilter<ILog, string>("locationId");
    clientOrganization = new ValueFilter<ILog, string>("clientOrganizationId");
    dataProviderOrganization = new ValueFilter<ILog, string>("dataProviderOrganizationId");
    messageType = new MultiValueFilter<ILog, string>("messageType");
    statusCode = new MultiValueFilter<ILog, string>("status");
    organization = new MultiPropertyFilter<ILog, string>(["dataProviderOrganizationId", "clientOrganizationId"]);
    constructor() {
        super();
        this.add(this.date, this.locationId, this.statusCode, this.clientOrganization, this.dataProviderOrganization, this.messageType, this.organization);

    }
}
