import { Injectable } from "@angular/core";
import { User, UserManager, WebStorageStateStore, UserManagerSettings, UserSettings } from "oidc-client";
import { BehaviorSubject, concat, from, Observable } from "rxjs";
import { filter, map, mergeMap, take, tap } from "rxjs/operators";
import { ApplicationPaths, ApplicationName } from "./api-authorization.constants";
import { Router } from "@angular/router";
import { INavigationState } from "../interfaces/interfaces";

export type IAuthenticationResult =
    SuccessAuthenticationResult |
    FailureAuthenticationResult |
    RedirectAuthenticationResult;

export interface SuccessAuthenticationResult {
    status: AuthenticationResultStatus.Success;
    state: any;
}

export interface FailureAuthenticationResult {
    status: AuthenticationResultStatus.Fail;
    message: string;
}

export interface RedirectAuthenticationResult {
    status: AuthenticationResultStatus.Redirect;
}

export enum AuthenticationResultStatus {
    Success,
    Redirect,
    Fail
}

export interface IUser {
    name?: string;
    role?: string;
    organizations?: string;
    sub: string;
}

@Injectable({
    providedIn: "root"
})
export class AuthorizeService {
    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    private popUpDisabled = true;
    private userManager!: UserManager;
    private userSubject: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);
    private isInitialized: Promise<void>;

    public isAuthenticated$: Observable<boolean>;

    constructor(private router: Router) {
        this.isInitialized = this.initialize();
        this.isAuthenticated$ = this.isAuthenticated();
    }

    public isAuthenticated(): Observable<boolean> {
        return this.getUser().pipe(map(u => !!u));
    }

    public getUser(): Observable<IUser | null> {
        return concat(
            this.userSubject.pipe(take(1), filter(u => !!u)),
            this.getUserFromStorage().pipe(filter(u => !!u), tap(u => this.userSubject.next(u))),
            this.userSubject.asObservable());
    }

    public getAccessToken(): Observable<string | null> {
        return from(this.initialize())
            .pipe(mergeMap(() => from(this.userManager.getUser())),
                map(user => user && user.access_token));
    }

    public getReturnUrl(): string {
        const returnUrl = this.router.routerState.snapshot.url;
        return returnUrl || ApplicationPaths.DefaultLoginRedirectPath;
    }

    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    public async signIn(state: INavigationState): Promise<IAuthenticationResult> {
        await this.initialize();
        let user: User;

        try {
            user = await this.userManager.signinSilent(this.createArguments());
            this.userSubject.next(user.profile);
            return this.success(state);
        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            console.log("Silent authentication error: ", silentError);

            try {
                if (this.popUpDisabled) {
                    throw new Error(
                        "Popup disabled. Change 'authorize.service.ts:AuthorizeService.popupDisabled' to false to enable it.");
                }
                user = await this.userManager.signinPopup(this.createArguments());
                this.userSubject.next(user.profile);
                return this.success(state);
            } catch (popupError: any) {
                if (popupError.message === "Popup window closed") {
                    // The user explicitly cancelled the login action by closing an opened popup.
                    return this.error("The user closed the window.");
                } else if (!this.popUpDisabled) {
                    console.log("Popup authentication error: ", popupError);
                }

                // PopUps might be blocked by the user, fallback to redirect
                try {
                    await this.userManager.signinRedirect(this.createArguments(state));
                    return this.redirect();
                } catch (redirectError: any) {
                    console.log("Redirect authentication error: ", redirectError);
                    return this.error(redirectError);
                }
            }
        }
    }

    public async completeSignIn(url: string): Promise<IAuthenticationResult> {
        try {
            await this.initialize();
            const user = await this.userManager.signinCallback(url);
            this.userSubject.next(user && user.profile);
            return this.success(user && user.state);
        } catch (error) {
            console.log("There was an error signing in: ", error);
            return this.error("There was an error signing in.");
        }
    }

    public async signOut(state: any): Promise<IAuthenticationResult> {
        try {
            if (this.popUpDisabled) {
                throw new Error(
                    "Popup disabled. Change 'authorize.service.ts:AuthorizeService.popupDisabled' to false to enable it.");
            }

            await this.initialize();
            await this.userManager.signoutPopup(this.createArguments());
            this.userSubject.next(null);
            return this.success(state);
        } catch (popupSignOutError) {
            console.log("Popup signout error: ", popupSignOutError);
            try {
                await this.userManager.signoutRedirect(this.createArguments(state));
                return this.redirect();
            } catch (redirectSignOutError: any) {
                console.log("Redirect signout error: ", popupSignOutError);
                return this.error(redirectSignOutError);
            }
        }
    }

    public async completeSignOut(url: string): Promise<IAuthenticationResult> {
        await this.initialize();
        try {
            const response = await this.userManager.signoutCallback(url);
            this.userSubject.next(null);
            return this.success(response && response.state);
        } catch (error: any) {
            console.log(`There was an error trying to log out '${error}'.`);
            return this.error(error);
        }
    }

    private createArguments(state?: any): any {
        return { useReplaceToNavigate: true, data: state };
    }

    private error(message: string): IAuthenticationResult {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    private success(state: any): IAuthenticationResult {
        return { status: AuthenticationResultStatus.Success, state };
    }

    private redirect(): IAuthenticationResult {
        return { status: AuthenticationResultStatus.Redirect };
    }

    private initialize(): Promise<void> {
        if (this.isInitialized != null) {
            return this.isInitialized;
        }

        return new Promise((resolve, reject) => {
            fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl).then(async response => {

                if (!response.ok) {
                    throw new Error(`Could not load settings for '${ApplicationName}'`);
                }

                const settings: any = await response.json();
                settings.automaticSilentRenew = true;
                settings.includeIdTokenInSilentRenew = true;
                settings.userStore = new WebStorageStateStore({ store: window.localStorage });

                // implement kinda "addUserSignedIn" event subscription cause UserManager does not have such event.
                window.addEventListener("storage",
                    (evt) => {
                        // if localStorage item with known key has been set and it has value
                        // it means authenticated user was added in SPA in another tab
                        if (evt?.key && evt.key.startsWith("oidc.user") && evt.key.endsWith(ApplicationName) && evt.newValue) {
                            this.userSubject.next((JSON.parse(evt.newValue) as UserSettings).profile);
                        }
                    });
                this.userManager = new UserManager(settings as UserManagerSettings);

                this.userManager.events.addUserSignedOut(async () => {
                    await this.userManager.removeUser();
                    this.userSubject.next(null);
                });

                resolve();
            });
        });
    }

    private getUserFromStorage(): Observable<IUser | null> {
        return from(this.isInitialized)
            .pipe(
                mergeMap(() => this.userManager.getUser()),
                map(u => u && u.profile));
    }

    public async navigateToReturnUrl(returnUrl: string) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        await this.router.navigateByUrl(returnUrl,
            {
                replaceUrl: true
            });
    }
}
