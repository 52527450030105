import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../components/base.component";
import { ParameterApiService } from "../services/parameter.api.service";

@Component({
    selector: "app-logs",
    templateUrl: "./logs.component.html",
    styleUrls: ["./logs.component.scss"]
})
export class LogsComponent extends BaseComponent implements OnInit {

    messageTypes: { key: number, value: string }[] = [];
    statusCodes: { key: number, value: string }[] = [];

    constructor(private readonly parameterService: ParameterApiService) {
        super();
    }

    async ngOnInit() {
        this.messageTypes = await this.parameterService.getMessageTypes() ?? [];
        this.statusCodes = await this.parameterService.getStatusCodes() ?? [];
    }
}
