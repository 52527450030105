import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IOrganization } from "../../interfaces/api-models";
import { LogsFilters } from "../logs/logs-filters";
import { OrganizationsApiService } from "../services/organizations.api.service";
import { BaseComponent } from "./base.component";

@Component({
    selector: "organization-status",
    templateUrl: "./organization-status.component.html",
    styleUrls: ["./organization-status.component.scss"]
})
export class OrganizationStatusComponent extends BaseComponent {

    filterLatest = new LogsFilters();
    filterFailed = new LogsFilters();

    organization?: IOrganization;

    series: { name: string, data: number[] | undefined }[] = [];
    hours: string[] | undefined = [];

    constructor(
        private readonly organizationApi: OrganizationsApiService,
        private readonly activatedRoute: ActivatedRoute) {

        super();

        this.activatedRoute.params.subscribe(async routeParams => {
            const organizationId = routeParams["organizationId"];
            this.organization = await this.organizationApi.getOrganizationById(organizationId).toPromise();

            this.initLogs();
            this.initChartData();
        });
    }

    private initChartData() {

        if (this.organization == null) {
            return;
        }

        this.series = [{
            name: this.organization.name,
            data: this.organization.requestsPerHour
        }];

        this.hours = this.organization.requestHours;
    }

    private initLogs() {
        if (this.organization == null) {
            return;
        }

        this.filterLatest.clear();
        this.filterFailed.clear();

        this.filterFailed.organization.value = this.organization.id;
        this.filterLatest.organization.value = this.organization.id;

        this.filterFailed.statusCode.values = ["400", "401", "403", "500", "501"];
    }
}
