import { HttpRequest, HttpEvent, ɵHttpInterceptingHandler, HttpBackend } from "@angular/common/http";
import { Observable } from "rxjs";
import { Inject, Injectable, EnvironmentInjector } from "@angular/core";

/**
 * Inserts API_SEGMENT after BAE_HREF into request url.
 */
@Injectable({
    providedIn: "root"
})
export class HttpApiHandler extends ɵHttpInterceptingHandler  {
    constructor(@Inject("API_SEGMENT") private readonly apiSegment: string, backend: HttpBackend, injector: EnvironmentInjector) {
        super(backend, injector);
    }

    handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        return super.handle(req.clone({ url: this.apiSegment + req.url}));
    }
}
