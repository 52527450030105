<div class="container mat-typography">
    <h1>{{resources.Log.Details}}</h1>
    <div class="main-info">
        <div class="info-group">
            <div class="info-item">
                <span><strong>{{resources.Common.Id}}</strong></span>
                <span>{{log.id}}</span>
            </div>
            <div class="info-item">
                <span><strong>{{resources.Common.CreatedAt}}</strong></span>
                <span>{{log.createdAt}}</span>
            </div>
            <div class="info-item">
                <span><strong>{{resources.Log.Status}}</strong></span>
                <span>{{log.status}}</span>
            </div>
            <div class="info-item">
                <span><strong>{{resources.Log.ClientRequestLength}}</strong></span>
                <span>{{log.clientRequestLength}}</span>
            </div>
            <div class="info-item">
                <span><strong>{{resources.Log.ClientResponseLength}}</strong></span>
                <span>{{log.clientResponseLength}}</span>
            </div>
            <div class="info-item">
                <span><strong>{{resources.Log.Duration}}</strong></span>
                <span>{{log.duration}}</span>
            </div>
            <div class="info-item">
                <span><strong>{{resources.Log.LocationId}}</strong></span>
                <span>{{log.locationId}}</span>
            </div>
            <div class="info-item">
                <span><strong>{{resources.Log.LocationScheme}}</strong></span>
                <span>{{log.locationScheme}}</span>
            </div>
        </div>
        <div class="info-group">
            <div class="info-item">
                <span><strong>{{resources.Log.ClientOrganization}}</strong></span>
                <span>{{log.clientOrganizationName}}</span>
            </div>
            <div class="info-item">
                <span><strong>{{resources.Log.DataProviderOrganization}}</strong></span>
                <span>{{log.dataProviderOrganizationName}}</span>
            </div>
        </div>
    </div>

    <mat-card class="log-detail-card">
        <mat-card-actions align="end">
            <button class="card-container-right " mat-raised-button (click)="accordion.openAll()">{{resources.Log.ExpandAll}}</button>
            <button class="card-container-right" mat-raised-button (click)="accordion.closeAll()">{{resources.Log.CloseAll}} </button>
        </mat-card-actions>
        <mat-accordion #accordion="matAccordion" [multi]="true">
            <div class="info-item">
                <span><strong>{{resources.Log.ClientRequest}}</strong></span>
                <section>
                    <mat-expansion-panel class="accordion-panel"  #accordionPanelCReq="matExpansionPanel">
                        <mat-expansion-panel-header class="accordion-item-header">
                            <mat-panel-title>
                            </mat-panel-title>
                            <mat-panel-description>
                                {{ accordionPanelCReq.expanded ? resources.Log.CloseDetail : resources.Log.ExpandDetail}}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <pre class="info-item-text"> {{log.clientRequest}}</pre>
                    </mat-expansion-panel>
                </section>
            </div>
            <div class="info-item">
                <span><strong>{{resources.Log.ClientResponse}}</strong></span>
                <section>
                    <mat-expansion-panel class="accordion-panel" #accordionPanelCRes="matExpansionPanel">
                        <mat-expansion-panel-header class="accordion-item-header">
                            <mat-panel-title>
                            </mat-panel-title>
                            <mat-panel-description>
                                {{accordionPanelCRes.expanded ? resources.Log.CloseDetail : resources.Log.ExpandDetail}}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <pre class="info-item-text" *ngIf="log.clientResponse != null && log.clientResponse != 'empty' && log.clientResponse.length > 0">{{log.clientResponse}}</pre>
                        <pre class="info-item-text" *ngIf="log.clientResponse != null && log.clientResponse != 'empty' && log.clientResponse.length === 0">{{resources.Log.MessageTooHugeForRendering}} <button mat-raised-button color="primary" (click)="download()">{{resources.Common.Download}}</button></pre>
                    </mat-expansion-panel>
                </section>
            </div>
            <div class="info-item">
                <span><strong>{{resources.Log.DataProviderRequest}}</strong></span>
                <section>
                    <mat-expansion-panel class="accordion-panel" #accordionPanelDR="matExpansionPanel">
                        <mat-expansion-panel-header class="accordion-item-header">
                            <mat-panel-title>
                            </mat-panel-title>
                            <mat-panel-description>
                                {{accordionPanelDR.expanded ? resources.Log.CloseDetail : resources.Log.ExpandDetail}}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <pre class="info-item-text">{{log.dataProviderRequest}}</pre>
                    </mat-expansion-panel>
                </section>
            </div>
            <div class="info-item">
                <span><strong>{{resources.Log.DataProviderResponse}}</strong></span>
                <section>

                    <mat-expansion-panel class="accordion-panel" #accordionPanelDPR="matExpansionPanel">
                        <mat-expansion-panel-header class="accordion-item-header">
                            <mat-panel-title>
                            </mat-panel-title>
                            <mat-panel-description>
                                {{accordionPanelDPR.expanded ? resources.Log.CloseDetail : resources.Log.ExpandDetail}}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <pre class="info-item-text">{{log.dataProviderResponse}}</pre>
                    </mat-expansion-panel>

                </section>
            </div>
        </mat-accordion>
    </mat-card>

 </div>
