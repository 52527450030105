import { Injectable } from "@angular/core";

import { BaseResources } from "common.module/resources";

import { enResources } from "./lang/en";
import { IResources } from "./resources.d";

@Injectable({ providedIn: "root" })
export class Resources extends BaseResources<IResources> {
    private static _instance: Resources;

    constructor() {
        super();

        if (!Resources._instance) {
            this.addResources(enResources, "en", "English");
            Resources._instance = this;
            this.language = this.getDefaultCulture().id;
        }

        return Resources._instance;
    }
}

export const resources = new Resources();
export { IResources } from "./resources.d";