import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root"
})

export class ParameterApiService {
    constructor(private readonly api: ApiService) {
    }

    getMessageTypes() {
        return this.api.get<{ key: number, value: string }[]>("parameter/message-types").toPromise();
    }

    getStatusCodes() {
        return this.api.get<{ key: number, value: string }[]>("parameter/status-codes").toPromise();
    }

    getOrganizationTypes() {
        return this.api.get<{ key: number, value: string }[]>("parameter/organization-types").toPromise();
    }

    getEmailTypes() {
        return this.api.get<{ key: number, value: string }[]>("parameter/email-types").toPromise();
    }
}
