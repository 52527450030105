import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { IEmailInformationModel, IOrganizationManagementModel, IOrganizationTypeModel } from "../../interfaces/api-models";
import { AdminApiService } from "../services/admin.api.service";
import { BaseComponent } from "../components/base.component";
import { NotificationService } from "../services/notification.service";
import { ModalService } from "../services/modal.service";
import { MatTableDataSource } from "@angular/material/table";
import { ManageOrganizationTypeComponent } from "./manage-organization-type.component";
import { ParameterApiService } from "app/services/parameter.api.service";

@Component({
    selector: "organization-detail",
    styleUrls: ["organization-detail.component.scss"],
    templateUrl: "./organization-detail.component.html",
})

export class OrganizationDetailComponent extends BaseComponent implements OnInit {

    dataSource = new MatTableDataSource<IOrganizationTypeModel>();
    organization!: IOrganizationManagementModel;
    organizationTypes: IOrganizationTypeModel[] = [];
    emailTypes: { key: number, value: string }[] = [];
    emailToAdd?: string | undefined;
    selectedEmailType?: { key: number, value: string } | undefined;

    maxAllowedCharacters = 50;

    form: FormGroup = this.formBuilder.group({
        name: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        authenticationUrl: [, { validators: [Validators.pattern(new RegExp("([a-z]+\:\/+)([^\/\s]*)([a-z0-9\-@\^=%&;\/~\+]*)[\?]?([^ \#]*)#?([^ \#]*)"))] }],
        serviceUrl: [, { validators: [Validators.pattern(new RegExp("([a-z]+\:\/+)([^\/\s]*)([a-z0-9\-@\^=%&;\/~\+]*)[\?]?([^ \#]*)#?([^ \#]*)"))] }],
        region: [, { validators: [Validators.required, Validators.minLength(3), Validators.maxLength(3)] }],
        email: [, { validators: [Validators.email] }],
        emailType: [],
        streetAddress: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        state: [, { validators: [Validators.maxLength(this.maxAllowedCharacters)] }],
        zipCode: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        city: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        country: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }]
    });

    constructor(
        private readonly adminService: AdminApiService,
        private readonly parameterApiService: ParameterApiService,
        private readonly route: ActivatedRoute,
        private readonly notificationService: NotificationService,
        private readonly formBuilder: FormBuilder,
        private readonly modalService: ModalService) {
        super();
    }

    get isEmailRequired(): boolean {
        return this.organization.emailInformations == null || (this.organization.emailInformations != null && this.organization.emailInformations.length <= 0);
    }

    get isEmailTypeRequired(): boolean {
        if (!this.isEmpty("email") && this.isFieldValid("email")) {
            this.form.controls["emailType"].markAsTouched();
            return true;
        }

        this.form.controls["emailType"].markAsUntouched();
        return false;
    }

    async ngOnInit() {
        var org = await this.adminService.getOrganization(this.route.snapshot.params["organizationId"]);
        if (org != null) {
            this.organization = org;
            //this.form.get('selectedEmails')!.setValue([...this.organization.emails]);
            await this.initEmailTypes();
            await this.initOrganizationTypes();
            if (this.organization.emailInformations == null || this.organization.emailInformations.length <= 0) {
                this.organization.emailInformations = [];
                this.setEmailFormControlAsEmpty();
            }
        }
    }

    async updateOrganization() {
        const result = await this.adminService.updateOrganization(this.organization);

        if (result?.succeeded) {
            this.notificationService.showNotification([this.resources.Account.OrganizationUpdated], true);
        }
        else {
            this.notificationService.showNotification(result?.errors, false);
        }
    }

    get isEmailAddingDisabled() {
        if (this.isEmpty("emailType") || !this.isFieldValid("emailType")) {
            return true;
        }

        if (this.isEmpty("email") || !this.isFieldValid("email")) {
            return true;
        }

        return false;
    }

    isFieldValid(fieldName: string) {
        const fieldControl = this.form.get(fieldName);

        if (fieldControl) {
            return fieldControl.valid;
        }

        return false;
    }

    async addEmail(value: string | undefined) {
        const existingEmail = this.organization.emailInformations?.filter(e => e.email === value);
        
        if (existingEmail.length === 0 && value != null) {
            this.organization.emailInformations.push({ email: value, type: this.selectedEmailType?.value ?? ""});
            const result = await this.adminService.updateOrganizationEmail(this.organization);

            if (result?.succeeded) {
                this.notificationService.showNotification([this.resources.Account.OrganizationEmailUpdated], true);
            }
            else {
                this.notificationService.showNotification(result?.errors, false);
                this.removeEmail(value);
            }
            
        }

        this.form.controls["email"].reset();
        this.form.controls["emailType"].reset();
    }
    
    removeEmail(email: string) {
        if (this.organization.emailInformations != null) {
            const updatedEmails = this.organization.emailInformations.filter(e => e.email !== email);
            this.organization.emailInformations = updatedEmails;
        
            if (updatedEmails.length === 0) {
                this.setEmailFormControlAsEmpty();
            }
        }
    }

    isEmpty(fieldName: string) {
        const fieldControl = this.form.get(fieldName);

        if (fieldControl) {
            return fieldControl.value == null || fieldControl.value === "";
        }

        return false;
    }

    getErrorMessage(fieldName: string) {
        const fieldControl = this.form.get(fieldName);
        if (fieldControl) {
            if (fieldControl.hasError("required")) {
                return this.resources.Error.Required;
            }

            if (fieldControl.hasError("email")) {
                return this.resources.Error.NotValidEmail;
            }

            if (fieldControl.hasError("pattern")) {
                return this.resources.Error.NotValidUrlPattern;
            }

            if (fieldControl.hasError("minlength") || fieldControl.hasError("maxlength")) {
                if (fieldName === "region") {
                    return this.resources.Error.RegionLength;
                }
                if (fieldName === "name") {
                    return this.resources.Error.NameLength;
                }
                if (fieldName === "streetAddress") {
                    return this.resources.Error.AddressLength;
                }
                if (fieldName === "state") {
                    return this.resources.Error.StateLength;
                }
                if (fieldName === "zipCode") {
                    return this.resources.Error.ZipCodeLength;
                }
                if (fieldName === "city") {
                    return this.resources.Error.CityLength;
                }
                if (fieldName === "country") {
                    return this.resources.Error.CountryLength;
                }
            }
        }

        return "";
    }

    toggleCheckboxValue(name: "canSendRequest" | "canReceiveRequest") {
        this.organization[name] = !this.organization[name];
    }

    orgTypeChanged(orgType: any) {
        this.organization.type = orgType;
    }

    emailTypeChanged(emailType: any) {
        this.selectedEmailType = emailType;
    }

    async openManageOrganizationTypeModal() {
        const actionCommitted = await this.modalService.openModalFromTemplate<boolean>(ManageOrganizationTypeComponent);
        if (actionCommitted) {
            await this.initOrganizationTypes();
        }
    }

    private async initOrganizationTypes() {
        this.organizationTypes = await this.adminService.getOrganizationTypes() ?? [];
    }

    private async initEmailTypes() {
        this.emailTypes = await this.parameterApiService.getEmailTypes() ?? [];
    }

    private setEmailFormControlAsEmpty() {
        this.form.controls["email"].setValue(null);
        this.form.controls["email"].markAsTouched();
    }
}
