import { Injectable } from "@angular/core";
import { IAddNewUser, IChangePasswordModel, ICreationResult, IOrganizationEmailModel, IOrganizationManagementModel, IOrganizationTypeModel, IRole, IUser } from "../../interfaces/api-models";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root"
})
export class AdminApiService {
    constructor(private readonly api: ApiService) {
    }

    getUsers() {
        return this.api.get<IUser[]>("userManagement").toPromise();
    }

    getRoles() {
        return this.api.get<IRole[]>("userManagement/roles").toPromise();
    }

    getUser(userId: string) {
        return this.api.get<IUser>("userManagement/" + userId).toPromise();
    }

    addUser(model: IAddNewUser) {
        return this.api.post<ICreationResult>("userManagement/add", model).toPromise();
    }

    deleteUser(userId: string) {
        return this.api.delete<IUser>("userManagement/" + userId).toPromise();
    }

    updateUser(user: IUser) {
        return this.api.put<ICreationResult>("userManagement", user).toPromise();
    }

    changePassword(model: IChangePasswordModel) {
        return this.api.post<ICreationResult>("userManagement/change-password", model).toPromise();
    }

    addOrganization(model: IOrganizationManagementModel) {
        return this.api.post<ICreationResult>("organizationManagement/add", model).toPromise();
    }
    
    exportContacts() {
        return this.api.post("organizationManagement/export-contacts", null, { responseType: 'blob' }).toPromise();
    }

    sendEmailToOrganizations(model: IOrganizationEmailModel) {
        return this.api.post<ICreationResult>("organizationManagement/send-email", model).toPromise();
    }

    getOrganization(orgId: string) {
        return this.api.get<IOrganizationManagementModel>("organizationManagement/" + orgId).toPromise();
    }

    updateOrganization(org: IOrganizationManagementModel) {
        return this.api.put<ICreationResult>("organizationManagement", org).toPromise();
    }

    updateOrganizationEmail(org: IOrganizationManagementModel) {
        return this.api.put<ICreationResult>("organizationManagement/update-email", org).toPromise();
    }

    addOrganizationType(model: IOrganizationTypeModel) {
        return this.api.post<ICreationResult>("organizationType/add", model).toPromise();
    }

    getOrganizationTypes() {
        return this.api.get<IOrganizationTypeModel[]>("organizationType").toPromise();
    }

    updateOrganizationType(org: IOrganizationTypeModel) {
        return this.api.put<ICreationResult>("organizationType", org).toPromise();
    }

    deleteOrganizationType(typeId: string) {
        return this.api.delete<ICreationResult>("organizationType/" + typeId).toPromise();
    }
}
