import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BaseComponent } from './base.component';

@Component({
  selector: 'app-select-all-option',
  templateUrl: './select-all-option.component.html',
  styleUrls: ['./select-all-option.component.scss']
})
export class SelectAllOptionComponent extends BaseComponent {
  protected unsubscribe!: Subject<any>;

  @Input() control!: FormControl;
  @Input() values: any[] = [];
  
  isChecked(): boolean {
    return (Boolean)(this.control.value && this.values.length
      && this.control.value.length === this.values.length);
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.control.setValue(this.values.map(x => x.key));
    } else {
      this.control.setValue([]);
    }
  }
}
