import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import {  MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { FiltersModule } from "common.module/filters";
import { HighchartsChartModule } from "highcharts-angular";
import { ChangePasswordComponent } from "./change-password.component";
import { ChartComponent } from "./chart.component";
import { ConfirmDialogComponent } from "./confirm-dialog.component";
import { LoadingPanelComponent } from "./loading-panel.component";
import { LogTableComponent } from "./log-table.component";
import { OrganizationStatusComponent } from "./organization-status.component";
import { OrganizationSummaryComponent } from "./organization-summary.component";
import { OverallStatusComponent } from "./overall.component";
import { SelectAllOptionComponent } from "./select-all-option.component";
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        MatButtonModule,
        ReactiveFormsModule,
        FormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCardModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatListModule,
        MatCardModule,
        MatFormFieldModule,
        MatDialogModule,
        MatChipsModule,
        MatInputModule,
        MatDividerModule,
        MatMenuModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatCheckboxModule,
        FiltersModule,
        HighchartsChartModule
    ],
    declarations: [
        LoadingPanelComponent,
        LogTableComponent,
        OrganizationSummaryComponent,
        OrganizationStatusComponent,
        OverallStatusComponent,
        ConfirmDialogComponent,
        SelectAllOptionComponent,
        ChartComponent,
        ChangePasswordComponent
        
    ],
    exports: [
        LoadingPanelComponent,
        LogTableComponent,
        OrganizationSummaryComponent,
        OrganizationStatusComponent,
        OverallStatusComponent,
        ConfirmDialogComponent,
        SelectAllOptionComponent,
        ChartComponent,
        ChangePasswordComponent
        
    ]
})
export class ComponentsModule {
}
