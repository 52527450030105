import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpApiHandler } from "./http-api.handler";

@Injectable()
export class ApiService extends HttpClient {
    constructor(httpHandler: HttpApiHandler) {
        super(httpHandler);
    }
}
