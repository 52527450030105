import { Filter, IFilter } from "common.module/filters";
import { Subject, Subscribable } from "rxjs";

export class MultiPropertyFilter<T, TValue> implements IFilter<T> {

    title?: string;
    filterChanged = new Subject<void>();
    private _value?: TValue;

    readonly name: string;

    constructor(private fields: (keyof T)[]) {
        this.name = fields.map(x => x.toString().toLowerCase()).sort().join(",");
    }

    get isActive() {
        return true;
    }

    get value() {
        return this._value;
    }

    set value(value: TValue | undefined) {
        if (this._value !== value) {
            this._value = value;
            this.onValueChanged();
        }
    }

    protected onValueChanged() {
        this.filterChanged.next();
    }

    match(item: T) {
        throw new Error("filter doesn't work on client side");
        return false;
    }

    clear() {
        this.value = undefined;
    }

    initialize(data: any) {
        throw new Error("filter doesn't work on client side");
    }

    fillParameters(data: any) {
        const properties = this.fields.join(",");
        if (this.value) {
            data[this.name] = this.value;
        }
    }

    getParameters(): {[key: string]: any;} {
        return {}
    }
}