import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { IOrganization, IOrganizationStatusResponseModel, ISimplifiedOrganization } from "../../interfaces/api-models";
import { HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class OrganizationsApiService {
    constructor(private readonly api: ApiService) {
    }

    get() {
        return this.api.get<IOrganization[]>("organizations");
    }

    getWithOutChartData(take: number, skip: number = 0, parameters: { [key: string]: any } = {}) {
        let params = new HttpParams();

        if (take != null) {
            params = params.append("take", take.toString());
        }

        if (skip != null) {
            params = params.append("skip", skip.toString());
        }

        for (const key in parameters) {
            if (Object.prototype.hasOwnProperty.call(parameters, key)) {
                params = params.append(key, parameters[key]);
            }
        }

        return this.api.get<IOrganizationStatusResponseModel>("organizations/withoutChartData", { params }).toPromise();
    }

    getSimplifiedOrganizations() {
        return this.api.get<ISimplifiedOrganization[]>("organizations/simplified").toPromise();
    }

    findByName(query: string) {
        return this.api.get<IOrganization[]>("organizations/search?query=" + query).toPromise();
    }

    getOrganizationById(orgId: string) {
        return this.api.get<IOrganization>("organizations/" + orgId);
    }
}
