import { Subject } from "rxjs";
import { OnDestroy, Directive } from "@angular/core";

import { resources } from "app/resources/resources";

// https://angular.io/guide/ivy-compatibility-examples#undecorated-classes
@Directive()
export abstract class BaseComponent implements OnDestroy {
    protected ngDestroy$ = new Subject<void>();
    resources = resources.data;

    ngOnDestroy() {
        // avoid memory leaks here by cleaning up after ourselves.
        this.ngDestroy$.next();
        this.ngDestroy$.complete();
    }
}
