import { Component, OnInit } from "@angular/core";
import { OrganizationsApiService } from "../services/organizations.api.service";
import { IOrganization, ISimplifiedOrganization } from "../../interfaces/api-models";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "../components/base.component";

@Component({
    selector: "app-home",
    styleUrls: ["home.component.scss"],
    templateUrl: "./home.component.html",
})
export class HomeComponent extends BaseComponent implements OnInit {

    static organizations: ISimplifiedOrganization[] = [];
    organizationId?: string = undefined;

    constructor(
        private readonly organizationsApi: OrganizationsApiService,
        private readonly route: ActivatedRoute) {
        super();
    }

    async ngOnInit() {
        if (this.route.firstChild) {
            this.organizationId = this.route.firstChild.snapshot.params["organizationId"];
        }
    }

    get organizations(): ISimplifiedOrganization[] {
        return HomeComponent.organizations;
    }

    tabChanged(orgId?: string){
        this.organizationId = orgId;
    }
}