import { Injectable, Inject } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";

/**
 * Ensures that all XHR requests go to local API server.
 */
@Injectable({
    providedIn: "root"
})
export class SameOriginInterceptor implements HttpInterceptor {
    constructor(@Inject("BASE_URL") private baseUrl: string) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = req.url.startsWith(this.baseUrl) ? req.url : this.baseUrl + req.url;
        req = req.clone({ url });
        return next.handle(req);
    }
}
