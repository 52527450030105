import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import * as Oidc from "oidc-client";

export function getBaseUrl() {
  return document.getElementsByTagName("base")[0].href;
}

const providers = [
  { provide: "BASE_URL", useFactory: getBaseUrl, deps: [] },
  { provide: "API_SEGMENT", useValue: "api/", deps: [] } // TODO move to settings or octopus transformations
];

if (environment.production) {
  enableProdMode();
}

//Oidc.Log.logger = console;

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));
