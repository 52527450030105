import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

import { IUserSettings } from "../../interfaces/interfaces";

@Injectable({
    providedIn: "root"
})

export class LocalUserSettingsService {

    userSettings: IUserSettings = {} as IUserSettings;

    private storeKey = "idden-user-settings";

    constructor() {

        const storedData = localStorage.getItem(this.storeKey);

        if (storedData != null && storedData.length > 0) {
            this.userSettings = JSON.parse(storedData);
        } else {
            this.userSettings = this.getDefaultSettings();
        }
    }

    getSettings(): IUserSettings {
        return this.userSettings;
    }

    changeState(field: keyof IUserSettings, newValue: number) {
        this.userSettings[field] = newValue;
        this.saveSettings();
    }

    saveSettings() {
        localStorage.setItem(this.storeKey, JSON.stringify(this.userSettings));
    }

    private getDefaultSettings(): IUserSettings {
        return {
            logsPageSize: 10,
            userManagementPageSize: 10,
            organizationManagementPageSize: 10,
            organizationTypePageSize: 5,
            translationManagemntPageSize: 10,
        };
    }
}
