// This code was generated by a tool! Changes to this file may cause incorrect behavior and will be lost if the code is regenerated.
// tslint:disable

import { IResources } from "../resources.d";

export const enResources: IResources = {
    Account: {
        Organization: "Organization",
        AddOrganization: "Add new organization",
        AddOrganizationType: "Add new organization type",
        ManageOrganizationType: "Manage organization types",
        AddUser: "Add new user",
        ChangePassword: "Change password",
        DeleteConfirmation: "Are you sure you want to delete?",
        DeleteOrganization: "Delete organization",
        DeleteUser: "Delete user",
        Email: "Email",
        NewPassword: "New password",
        OldPassword: "Old password",
        OrganizationCreated: "New organization created",
        OrganizationDeleted: "Organization deleted",
        Organizations: "Organizations",
        OrganizationUpdated: "Organization updated",
        OrganizationEmailUpdated: "Organization email updated",
        OrganizationTypeCreated: "New organization type created",
        OrganizationTypeUpdated: "Organization type updated",
        OrganizationTypeDeleted: "Organization type deleted",
        OrganizationTypeConfirmDelete: "Delete organization type",
        OrganizationTypeDeleteRestriction: "This type can not be deleted as the following organizations are assosiated with it.",
        OrganizationTypeSelect: "Select organization type",
        EmailTypeSelect: "Select email type",
        Role: "Role",
        UserCreated: "New user created",
        UserDeleted: "User deleted",
        UserName: "User name",
        UserUpdated: "User updated",
        ProceedConfirmation: "Are you sure you want to proceed?",
        ExportContacts: "Export contacts",
        SendEmail: "Send email",
        EmailSent: "Email sent",
        SendEmailToOrganizations: "Send email to organizations"
    },
    Common: {
        Add: "Add",
        AddAsNew: "Add as new",
        Edit: "Edit",
        Cancel: "Cancel",
        Send: "Send",
        Change: "Change",
        Copy: "Copy",
        CreatedAt: "Created at",
        DateRangeFrom: "From",
        DateRangeTo: "To",
        Download: "Download",
        Id: "Id",
        Save: "Save",
        Delete: "Delete",
        Update: "Update",
        Action: "Actions",
        Clear: "Clear",
        DeleteConfirmation: "Are you sure you want to delete this item?",
        AddConfirmation: "Are you sure you want to add this item?",
        SelectAll: "Select all",
    },
    Email: {
        ConfirmationSubject: "Email confirmation"
    },
    Error: {
        AddressLength: "Address must be shorter than 50 characters",
        CodeLength: "Code must be shorter than 50 characters",
        DisplayNameLength: "Display name must be shorter than 100 characters",
        ArrayCannotBeEmpty: "Array cannot be empty",
        BaseUrlCannotBeNull: "BaseUrl cannot be null",
        BaseUrlWrongFormat: "BaseUrl contains wrong url format",
        SubjectLength: "Subject must be within 200 characters",
        MessageLength: "Message must be within 2000 characters",
        CityLength: "City must be shorter than 50 characters",
        CountryLength: "Country must be shorter than 50 characters",
        DataProviderMustExist: "Data provider organization must exist",
        IDDENHeaderMustBeDefinedOnce: "{0} must be defined once",
        IDDENHeaderMustBePresent: "{0} must be present in headers",
        IDDENHeaderMustHaveValue: "{0} must have value",
        NameLength: "Name must be shorter than 50 characters",
        TitleLength: "Title must be shorter than 50 characters",
        AcronymLength: "Acronym must be shorter than 50 characters",
        NotValidEmail: "Not a valid email",
        NotValidUrlPattern: "Not valid url pattern",
        OrganizationHasNoAccess: "Organization has no access",
        RegionLength: "Region must be 3 characters",
        Required: "You must enter a value",
        RequiredFieldNull: "Required field cannot be null: {0}",
        StateLength: "State must be shorter than 50 characters",
        TargetOrganizationCannotBeUsedAsEndpoint: "TargetOrganization cannot be used as endpoint",
        TargetOrganizationNotPresent: "TargetOrganization not present",
        TargetOrganizationUnsupportApiVersion: "ApiVersion is not supported by TargetOrganization",
        UnsupportedAuth: "This auth standard is not supported",
        WrongOrganizationIdentifierFormat: "Wrong IDDEN-ID format",
        ZipCodeLength: "ZipCode must be shorter than 50 characters"
    },
    Identity: {
        ConfirmEmailText: "Please confirm your account by clicking this link:",
        CreateNewPassword: "Create a new password",
        ForgotPasswordConfirmation: "Forgot password confirmation",
        ForgotYourPassword: "Forgot your password?",
        Login: "Log in",
        LoginRedirect: "Click here to log in",
        Logout: "Log out",
        ReserPassword: "Reset password",
        Reset: "Reset",
        ResetPasswordConfirmation: "Reset password confirmation",
        ResetPasswordEmailSent: "Please check your email to reset your password.",
        ResetPasswordEmailText: "Reset your password by clicking this link:",
        ResetYourPassword: "Reset your password.",
        SetPassword: "Set password",
        Submit: "Submit",
        SuccessfulLogout: "You have successfully logged out of the application.",
        SuccessfulPasswordReset: "Your password has been reset."
    },
    Log: {
        ClientFilterPlaceholder: "Select client",
        ClientFilterValuePrefix: "Client:",
        ClientOrganization: "Client organization",
        ClientRequest: "Client request",
        ClientResponse: "Client response",
        ClientRequestLength: "Client request length",
        ClientResponseLength: "Client response length",
        DataProviderFilterPlaceholder: "Select data provider",
        DataProviderFilterValuePrefix: "Data provider:",
        DataProviderOrganization: "Data provider organization",
        DataProviderRequest: "Data provider request",
        DataProviderResponse: "Data provider response",
        Details: "Details",
        Duration: "Duration (ms)",
        FailedLogs: "Failed requests",
        LatestLogs: "Latest requests",
        LocationId: "Location Id",
        LocationIdPlaceholder: "Type location id",
        LocationScheme: "Location scheme",
        MessageTooHugeForRendering: "Log is too huge for rendering. You can download it",
        MessageType: "Message type",
        MessageTypeFilterPlaceholder: "Select message type",
        MessageTypeFilterValuePrefix: "Message type:",
        Status: "Status",
        StatusCodeFilterValuePrefix: "Status:",
        StatusFilterPlaceholder: "Select status",
        ExpandAll: "Expand All",
        CloseAll: "Close All",
        ExpandDetail: "Click here to expand",
        CloseDetail: "Click here to close",
    },
    Organization: {
        AllLogs: "All",
        AuthenticationUrl: "Authentication url",
        CanReceiveRequest: "Can receive request",
        CanSendRequest: "Can send request",
        City: "City",
        CloudProvider: "Cloud provider",
        Country: "Country",
        FailedLogs: "Failed",
        FarmSoftware: "Farm software",
        Idden: "iDDEN",
        Identifier: "Identifier",
        MilkRecordingOrganization: "Milk recording organization",
        Name: "Name",
        Overall: "Overall",
        Region: "Region",
        RequestPerHour: "Last 24h request per hour",
        Requests: "Requests",
        Responses: "Responses",
        Service: "Service",
        ServiceUrl: "Service url",
        State: "State",
        Statuses: "Organizations statuses",
        StreetAddress: "Street address",
        SucceededLogs: "Succeeded",
        TransactionTime: "Transaction time",
        Type: "Organization type",
        EmailType: "Email type",
        ZipCode: "Zip code",
        Subject: "Subject",
        Message: "Message"
    },
    OrganizationType: {
        Name: "Name",
        Title: "Title",
        Acronym: "Acronym",
    },
    Statistics: {
        DateRange: "Date range",
        Locations: "Locations",
        LogAggregation: "Aggregation",
        LogStatus: "Log status",
        Perspective: "Perspective",
        SelectLocations: "Select locations",
        SelectOrganization: "Select organization",
        Aggregation: {
            ByDay: "by day",
            ByHour: "by hour",
            ByHour8: "by 8 hours",
            ByMinutes15: "by 15 minutes",
            ByMinutes30: "by 30 minutes",
            ByMonth: "by month",
            ByWeek: "by week"
        },
        Range: {
            LastDay: "Last day",
            LastHour: "Last 1 hour",
            LastHour8: "Last 8 hours",
            LastMonth: "Last month",
            LastWeek: "Last week",
            LastYear: "Last year"
        },
        Status: {
            All: "All",
            Error: "Error",
            Success: "Success"
        }
    },
    Translation: {
        AddLanguage: "Add language from library",
        CreateLanguage: "Create new language",
        EditTranslation: "Edit translation",
        Languages: "Languages",
        Language: "Language",
        iDDENLibrary: "Languages from iDDEN library",
        AddLanguageFromIddenLibrary: "Add language from iDDEN library",
        RemoveLanguage: "Remove this language",
        Translation: "Translation",
        LanguageName: "Language name",
        Code: "Code",
        Copied: "Copied",
        Name: "Name",
        CopyLanguageFromMRO: "Copy language from MRO",
        LanguageCopiedFromMRO: "Language copied from MRO",
        CopyToiDDEN: "Copy to iDDEN library",
        CopyMROLanguageToiDDENLbrary: "Copy MRO language to iDDEN library",
        ViewLanguages: "View languages",
        LanguageCreated: "New language created",
        LanguageUpdated: "Language updated",
        LanguageDeleted: "Language deleted",
        LanguageExistsMessage: "A language with the same name is in this organization.",
        LanguageCopiedToLibrary: "Language copied to library",
        CopyLanguage: "Copy language",
        NotFound: "Language not found",
        AlreadyExists: "A language with the same name or code already exists.",
        LanguageSynced: "Language synced with ADE",
        SyncLanguage: "Sync language with ADE",
        LastSynced: "Last synced",
        SyncAllLanguages: "Sync all languages with ADE",
        ManageLanguages: "Manage languages",
        NewLanguageNameError: "Enter a new name and code to create a new langage from library"
    },
};
