import { DatePipe } from "@angular/common";
import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { of } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ILog, IOrganization } from "../../interfaces/api-models";
import { LogsFilters } from "../logs/logs-filters";
import { LogsApiService } from "../logs/logs.api.service";
import { OrganizationsApiService } from "../services/organizations.api.service";
import { BaseComponent } from "./base.component";
import { PageEvent } from "@angular/material/paginator";
import { UserAccessService } from "../services/user-access.service";
import { LocalUserSettingsService } from "../services/local-user-settings.service";
import { FormControl } from "@angular/forms";

@Component({
    selector: "log-table",
    templateUrl: "./log-table.component.html",
    styleUrls: ["./log-table.component.scss"]
})
export class LogTableComponent extends BaseComponent implements OnInit, AfterViewInit {

    @Input() inputFilters = new LogsFilters();
    @Input() messageTypes: { key: number, value: string }[] = [];
    @Input() statusCodes: { key: number, value: string }[] = [];
    @Input() organization!: IOrganization;

    @Input() disableFilters = false;
    @Input() hidePaginator = false;

    logs!: ILog[];
    isLoading = false;

    dataSource = new MatTableDataSource<ILog>();
    columnsNames: string[] = [];
    columns = [
        { name: "id"},
        { name: "messageType"},
        { name: "locationId"},
        { name: "clientOrganization"},
        { name: "dataProviderOrganization"},
        { name: "createdAt"},
        { name: "status"},
        { name: "duration"}
    ];

    datePipe = new DatePipe("en-US");

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    clientOrganizations: IOrganization[] = [];
    dataProviderOrganizations: IOrganization[] = [];
    locationIds: string[] = [];

    selectedClientOrganization?: IOrganization;
    selectedDataProviderOrganization?: IOrganization;

    selectedStatuses: string[] = [];
    selectedMessageTypes: string[] = [];
    selectedLocationId?: string;

    locationIdQuery: string | undefined;
    dataProviderOrganizationQuery: string | undefined;
    clientOrganizationQuery: string | undefined;

    filters = new LogsFilters();
    selectMessageTypes = new FormControl();
    selectStatuses = new FormControl();

    allSelected=false;
    constructor(private readonly organizationService: OrganizationsApiService,
        private readonly logService: LogsApiService,
        private readonly userSettingsService: LocalUserSettingsService,
        private readonly userAccessService: UserAccessService) {
        super();
    }

    async ngOnInit() {
        const userSettings = this.userSettingsService.getSettings();
        this.paginator.pageSize = this.hidePaginator ? 20 : userSettings.logsPageSize;

        this.columnsNames = this.columns.map(x => x.name);

        if (this.inputFilters.length > 0) {
            this.filters = this.inputFilters;
        }

        this.logs = [];
        this.dataSource = new MatTableDataSource<ILog>(this.logs);

        this.filters.filterChanged.pipe(debounceTime(200)).subscribe(() => {
            this.paginator.pageIndex = 0;
            this.getLogs();
        });
    }

    async ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
            this.getLogs();
        });

        setTimeout(() => this.getLogs());
    }

    hasAccess(organizationId: string) {
        return this.userAccessService.haveAccessToOrganization(organizationId);
    }

    pageChanged(event: PageEvent) {
        if (!this.hidePaginator) {
            this.userSettingsService.changeState("logsPageSize", event.pageSize);
        }
        this.getLogs(event.pageIndex * event.pageSize);
    }

    setStatusFilter() {
        const diff = this.filters.statusCode.values
                         .filter(x => !this.selectedStatuses.includes(x))
                         .concat(this.selectedStatuses.filter(x => !this.filters.statusCode.values.includes(x)));

        if (diff && diff.length === 0) {
            return ;
        }

        this.filters.statusCode.values = this.selectedStatuses;
    }

    setMessageFilter() {
        const diff = this.filters.messageType.values
            .filter(x => !this.selectedMessageTypes.includes(x))
            .concat(this.selectedMessageTypes.filter(x => !this.filters.messageType.values.includes(x)));

        if (diff && diff.length === 0) {
            return;
        }

        this.filters.messageType.values = this.selectedMessageTypes;
    }

    async getLogs(currentSize: number = 0) {
        this.isLoading = true;
        const sort = this.getSort();
        const data = await this.logService.getLogs(this.paginator.pageSize, currentSize, sort, this.organization?.id, this.filters.getParameters()).toPromise();

        if (data != null) {
            this.logs.length = currentSize;
            this.logs.push(...data.logs);
            this.logs.length = data.totalCount;
    
            this.dataSource.paginator = this.paginator;
        }

        this.isLoading = false;
    }

    formatDate = (date: Date | undefined, includeTime: boolean = true) => {
        const dateFormat = includeTime ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy";

        const result = this.datePipe.transform(date, dateFormat);
        return result === null ? "" : result;
    }

    isErrorStatus(status: number) {
        return !(status > 199 && status < 300);
    }

    search(query: string) {
        if (query == null || query.length < 3) {
            return of([]);
        }
        return this.organizationService.findByName(query);
    }

    formatOrganization(organization: IOrganization) {
        return organization?.name ?? "";
    }

    async onKeyUp(keyboardEvent: any, type: "client" | "dataProvider" | "locationId") {
        const query = keyboardEvent.target.value;

        if (type === "client") {
            this.clientOrganizations = await this.getFilteredOrganizations(query) ?? [];
        }
        else if (type === "dataProvider") {
            this.dataProviderOrganizations = await this.getFilteredOrganizations(query) ?? [];
        }
        else if (type === "locationId") {
            this.locationIds = await this.getFilteredLocationIds(query) ?? [];
        }
    }

    getStatusName(codes: string[]) {

        const statuses = codes.reduce((result, value) => {
            const status = this.statusCodes.find(x => x.key === +value);
            if (status) {
                result.push(status.value + " (" + status.key + ")");
            }
            return result;
        }, [] as string[]);
        return statuses.join(", ");
    }

    getMessageTypeName(codes: string[]) {

        const statuses = codes.reduce((result, value) => {
            const type = this.messageTypes.find(x => x.key === +value);
            if (type) {
                result.push(type.value);
            }
            return result;
        }, [] as string[]);
        return statuses.join(", ");
    }

    onClientOrganizationSelected(event: MatAutocompleteSelectedEvent) {
        this.selectedClientOrganization = event.option.value;
        this.filters.clientOrganization.value = this.selectedClientOrganization?.id;
    }

    removeSelectedClientOrganization() {
        this.selectedClientOrganization = undefined;
        this.clientOrganizationQuery = undefined;
        this.filters.clientOrganization.clear();
    }

    onDataProviderOrganizationSelected(event: MatAutocompleteSelectedEvent) {
        this.selectedDataProviderOrganization = event.option.value;
        this.filters.dataProviderOrganization.value = this.selectedDataProviderOrganization?.id;
    }

    removeSelectedDataProviderOrganization() {
        this.selectedDataProviderOrganization = undefined;
        this.dataProviderOrganizationQuery = undefined;
        this.filters.dataProviderOrganization.clear();
    }

    onLocationIdSelected(event: MatAutocompleteSelectedEvent) {
        this.selectedLocationId = event.option.value;
        this.filters.locationId.value = this.selectedLocationId;
    }

    removeSelectedLocationId() {
        this.selectedLocationId = undefined;
        this.locationIdQuery = undefined;
        this.filters.locationId.clear();
    }

    removeDateRangeFilter() {
        this.filters.date.clear();
    }

    removeStatusFilter() {
        if (!this.disableFilters) {
            this.selectedStatuses = [];
            this.filters.statusCode.clear();
        }
    }

    removeMessageTypeFilter() {
        if (!this.disableFilters) {
            this.selectedMessageTypes = [];
            this.filters.messageType.clear();
        }
    }

    private getFilteredOrganizations(query: string) {

        if (query == null || query.length < 3) {
            return [];
        }

        return this.organizationService.findByName(query);
    }

    private getFilteredLocationIds(query: string) {

        if (query == null || query.length < 3) {
            return [];
        }

        return this.logService.getLocationIdsByQuery(query).toPromise();
    }

    private getSort() {
        if (this.sort == null || this.sort.direction == null || this.sort.direction === "") {
            return undefined;
        }

        return `${this.sort.active}|${this.sort.direction}`;
    }
}
