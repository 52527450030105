import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { ICreationResult, ILanguageModel, ILanguagesModel, IOrganizationManagementsModel } from "../../interfaces/api-models";
import { HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class TranslationApiService {
    constructor(private readonly api: ApiService) {
    }

    private getParams(take?: number, skip?: number, sort?: string): HttpParams {
        let params = new HttpParams();

        if (take != null) {
            params = params.append("take", take.toString());
        }

        if (skip != null) {
            params = params.append("skip", skip.toString());
        }

        if (sort != null) {
            params = params.append("sort", sort.toString());
        }

        return params;
    }

    getEnumValues() {
        return this.api.get<ILanguageModel>("translateManagement/enum-values").toPromise();
    }

    addOrUpdateOrganizationLanguage(model: ILanguageModel) {
        return this.api.post<ICreationResult>("translateManagement/add-new-language", model).toPromise();
    }

    getLibraryLanguages(take?: number, skip?: number, sort?: string) {
        let params = this.getParams(take, skip, sort);

        return this.api.get<ILanguagesModel>("translateManagement/library-languages", { params }).toPromise();
    }

    getOrganizationsWithLanguages(take?: number, skip?: number, sort?: string) {
        let params = this.getParams(take, skip, sort);
        return this.api.get<IOrganizationManagementsModel>("translateManagement/mro-languages", { params }).toPromise();
    }

    getLanguagesOfOrganization(id: string, take?: number, skip?: number, sort?: string) {
        let params = this.getParams(take, skip, sort);
        return this.api.get<ILanguagesModel>("translateManagement/mro-languages/" + id, { params }).toPromise();
    }

    getLanguageDetail(languageId: string) {
        return this.api.get<ILanguageModel>("translateManagement/language-detail/" + languageId).toPromise();
    }

    copyOrganizationLanguageToLibrary(model: ILanguageModel) {
        return this.api.post<ICreationResult>("translateManagement/copy-language", model).toPromise();
    }

    syncToADE(id?: string) {
        return this.api.get<ICreationResult>("translateManagement/sync-language/" + id).toPromise();
    }

    syncAllLanguagesToADE(isForLibrary: boolean) {
        return this.api.get<ICreationResult>("translateManagement/sync-all-languages?isForLibrary=" + isForLibrary).toPromise();
    }

    deleteOrganizationLanguage(id: string) {
        return this.api.delete<ICreationResult>("translateManagement/delete-organzation-language/" + id).toPromise();
    }
}