import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MessageBoxService } from "common.module/components/message-box";
import { ICreationResult, IOrganizationTypeModel } from "../../interfaces/api-models";
import { BaseComponent } from "../components/base.component";
import { AdminApiService } from "../services/admin.api.service";
import { LocalUserSettingsService } from "../services/local-user-settings.service";
import { NotificationService } from "../services/notification.service";

@Component({
    selector: "manage-organization-type",
    styleUrls: ["manage-organization-type.component.scss"],
    templateUrl: "./manage-organization-type.component.html",
})
export class ManageOrganizationTypeComponent extends BaseComponent implements OnInit {

    dataSource = new MatTableDataSource<IOrganizationTypeModel>();
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    newOrganizationType = {} as IOrganizationTypeModel;
    maxAllowedCharacters = 50;
    isEdit = false;

    columns = ["organizationTypeName", "organizationTypeTitle", "organizationTypeAcronym", "update", "delete"];

    form: FormGroup = this.formBuilder.group({
        name: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        title: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        acronym: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
    });

    constructor(
        private readonly adminApiService: AdminApiService,
        private formBuilder: FormBuilder,
        private messageBoxService: MessageBoxService,
        private notificationService: NotificationService,
        private modal: MatDialogRef<ManageOrganizationTypeComponent>,
        private readonly userSettingsService: LocalUserSettingsService) {
        super();
    }

    async ngOnInit() {
        const userSettings = this.userSettingsService.getSettings();
        this.paginator.pageSize = userSettings.organizationTypePageSize;
        this.dataSource.paginator = this.paginator;
        await this.initOrganizationTypes();
    }

    async changeOrganizationType() {
        let result: ICreationResult | undefined;
        if (this.isEdit) {
            result = await this.adminApiService.updateOrganizationType(this.newOrganizationType);
        }
        else {
            result = await this.adminApiService.addOrganizationType(this.newOrganizationType);
        }
        if (result?.succeeded) {
            if (this.isEdit) {
                this.notificationService.showNotification([this.resources.Account.OrganizationTypeUpdated], true);
            }
            else {
                this.notificationService.showNotification([this.resources.Account.OrganizationTypeCreated], true);
            }
            this.clearForm();
        }
        else {
            this.notificationService.showNotification(result?.errors, false);
        }
    }

    async editOrganizationTypeModal(orgType: IOrganizationTypeModel) {
        this.newOrganizationType.id = orgType.id;
        this.newOrganizationType.name = orgType.name;
        this.newOrganizationType.title = orgType.title;
        this.newOrganizationType.acronym = orgType.acronym;
        this.isEdit = true;
    }

    async DeleteOrganizationType(orgType: IOrganizationTypeModel) {
        const confirm = await this.messageBoxService.confirm(this.resources.Common.DeleteConfirmation, this.resources.Account.OrganizationTypeConfirmDelete + ": " + orgType.name);
        if (confirm) {
            const result = await this.adminApiService.deleteOrganizationType(orgType.id);
            if (result?.succeeded) {
                this.notificationService.showNotification([this.resources.Account.OrganizationTypeDeleted], true);
                this.clearForm();
            } else {
                this.notificationService.showNotification(result?.errors, false);
            }
        }
    }

    isFieldValid(fieldName: string) {
        const fieldControl = this.form.get(fieldName);

        if (fieldControl) {
            return fieldControl.valid;
        }

        return false;
    }

    getErrorMessage(fieldName: string) {
        const fieldControl = this.form.get(fieldName);
        if (fieldControl) {

            if (fieldControl.hasError("required")) {
                return this.resources.Error.Required;
            }

            if (fieldControl.hasError("minlength") || fieldControl.hasError("maxlength")) {
                if (fieldName === "name") {
                    return this.resources.Error.NameLength;
                }
                if (fieldName === "title") {
                    return this.resources.Error.TitleLength;
                }
                if (fieldName === "acronym") {
                    return this.resources.Error.AcronymLength;
                }
            }
        }

        return "";
    }

    private async initOrganizationTypes() {
        this.dataSource.data = await this.adminApiService.getOrganizationTypes() ?? [];
    }

    pageChanged(event: PageEvent) {
        this.userSettingsService.changeState("organizationTypePageSize", event.pageSize);
    }

    clearForm() {
        this.form.reset();
        this.isEdit = false;
        this.initOrganizationTypes();
    }

    closeModal() {
        this.modal.close(true);
    }
}
