import { Component } from "@angular/core";

@Component({
    selector: "forbidden-error",
    templateUrl: "./forbidden-error.component.html",
    styleUrls: ["./forbidden-error.component.scss"]
})
export class ForbiddenErrorComponent {

}
