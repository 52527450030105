import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IConfirmDialogData } from "../../interfaces/api-models";
import { BaseComponent } from "../components/base.component";

@Component({
    selector: "confirm-dialog",
    styleUrls: ["confirm-dialog.component.scss"],
    templateUrl: "./confirm-dialog.component.html",
})
export class ConfirmDialogComponent extends BaseComponent implements OnInit {

    title = "";
    message = "";

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly data: IConfirmDialogData,
        private readonly modal: MatDialogRef<ConfirmDialogComponent>) {

        super();

        this.title = data.title ?? "Confirm action";
        this.message = data.message;
    }

    async ngOnInit() {
    }

    async confirm() {
        this.modal.close(true);
    }
}
