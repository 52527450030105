import { Component, OnInit } from "@angular/core";

import { BaseComponent } from "../components/base.component";

export enum ReportType {
    OrganizationsRequestByPeriod,
    OrganizationMessagesByPeriod,
    OrganizationLocationsByPeriod,
}

import * as Highcharts from "highcharts";

// @ts-ignore
import GroupedCategories from "highcharts-grouped-categories/grouped-categories";
GroupedCategories(Highcharts);

@Component({
    selector: "app-statistics",
    templateUrl: "./statistics.component.html",
    styleUrls: ["./statistics.component.scss"]
})
export class StatisticsComponent extends BaseComponent {

    selectedReportType?: ReportType;

    ReportType = ReportType;

    reportTypes = [
        {key: ReportType.OrganizationsRequestByPeriod, value: "Organizations requests by period"},
        {key: ReportType.OrganizationMessagesByPeriod, value: "Organization messages by period"},
        {key: ReportType.OrganizationLocationsByPeriod, value: "Organization locations by period"}
    ];

    getReportTitle() {
        if (this.selectedReportType == null) {
            return "";
        }

        return this.reportTypes[this.selectedReportType].value;
    }
}