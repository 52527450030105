<div class="container">

    <button mat-raised-button color="primary" (click)="openAddNewOrganizationModal()">{{resources.Account.AddOrganization}}</button>
    <button *ngIf="isAdmin" mat-raised-button color="primary" (click)="exportContacts()">{{resources.Account.ExportContacts}}</button>
    <button *ngIf="isAdmin" mat-raised-button color="primary" (click)="openSendEmailModal()">{{resources.Account.SendEmail}}</button>

    <mat-paginator [pageSizeOptions]="[10, 30, 50]" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
    <table mat-table matSort multiTemplateDataRows [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="organizationName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.Organization.Name}}</th>
            <td mat-cell *matCellDef="let org"><a mat-button color="primary" [routerLink]="org.id">{{org.name}}</a></td>
        </ng-container>

        <ng-container matColumnDef="organizationIdentifier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.Organization.Identifier}}</th>
            <td mat-cell *matCellDef="let org"><a mat-button color="primary" [routerLink]="org.id">{{org.identifier}}</a></td>
        </ng-container>

        <ng-container matColumnDef="organizationType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.Organization.Type}}</th>
            <td mat-cell *matCellDef="let org">{{org.type.name}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns" class="element-row"></tr>
    </table>
</div>
