import { ReturnUrlType } from "../api-authorization/api-authorization.constants";

export interface INavigationState {
    [ReturnUrlType]: string;
}

export interface IUserSettings {
    userManagementPageSize: number;
    logsPageSize: number;
    organizationManagementPageSize: number;
    organizationTypePageSize: number;
    translationManagemntPageSize: number;
}

export interface IDropdownOption {
    key: number;
    value: string;
}

export enum AggregationType {
    Minutes15,
    Minutes30,
    Hour,
    Hour8,
    Day,
    Week,
    Month,
}

export enum RangeType {
    Hour,
    Hour8,
    Day,
    Week,
    Month,
    Year,
}

export enum Status {
    All,
    Success,
    Error
}

export enum Perspective {
    Client,
    DataProvider
}

export enum Direction {
    Next = "next",
    Previous = "previous"
}
