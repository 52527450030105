<h2 mat-dialog-title>{{resources.Translation.CopyMROLanguageToiDDENLbrary}}</h2>
<mat-dialog-content class="mat-typography">
    <mat-paginator [pageSizeOptions]="[10, 30, 50]" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
    <table mat-table matSort multiTemplateDataRows [dataSource]="dataSource" matSortActive="createdAt" matSortDisableClear matSortDirection="desc" class="mat-elevation-z8">

        <!-- columns -->
        <ng-container matColumnDef="expandedLanguages">
            <th mat-header-cell *matHeaderCellDef>expandedLanguages</th>
            <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">
                <div class="expanded-content" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="language-container">
                        <div class="language-item" *ngIf="!isLoading">
                            <table *ngIf="element.languages.length > 0">
                                <tr>
                                  <th>{{resources.Translation.Languages}}</th>
                                  <th></th>
                                </tr>
                                <tr *ngFor="let language of element.languages" >
                                  <td class="display-name">{{language.displayName}}</td>
                                  <td>
                                    <button mat-stroked-button [disabled]="isAlreadyCopied(language)" 
                                      (click)="copyLanguageToLibrary(language, element)">
                                      {{ isAlreadyCopied(language) ? resources.Translation.Copied : resources.Translation.CopyToiDDEN }}
                                    </button>
                                  </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.Account.Organization}}</th>
            <td mat-cell *matCellDef="let element"><a *ngIf="!isLoading" mat-button color="primary">{{element.name}}</a></td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <a mat-button *ngIf="!isLoading && element.languages.length > 0" (click)="element.isExpanded = !element.isExpanded">{{resources.Translation.ViewLanguages}}</a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns" class="element-row" [class.expanded-row]="expandedElement === row"
        (click)="expandedElement = expandedElement === row ? null : row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedLanguages']" class="detail-row"></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>{{resources.Common.Cancel}}</button>
</mat-dialog-actions>