<h2 mat-dialog-title>{{resources.Account.ChangePassword}}</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="form" autocomplete="off">
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Account.OldPassword}}</mat-label>
            <input matInput type="password" [(ngModel)]="model.oldPassword" formControlName="oldPassword" required >
            <mat-error *ngIf="!isFieldValid('oldPassword')">{{getErrorMessage('oldPassword')}}</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Account.NewPassword}}</mat-label>
            <input matInput type="password" [(ngModel)]="model.newPassword" formControlName="newPassword" required>
            <mat-error *ngIf="!isFieldValid('newPassword')">{{getErrorMessage('newPassword')}}</mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{resources.Common.Cancel}}</button>
    <button mat-button [disabled]="form.invalid" cdkFocusInitial (click)="changePassword()">{{resources.Common.Change}}</button>
</mat-dialog-actions>
