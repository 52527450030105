import { Component } from "@angular/core";
import { LoadingService } from "../services/loading.service";
import { debounce } from "rxjs/operators";
import { Observable, interval } from "rxjs";

@Component({
    selector: "loading",
    templateUrl: "./loading-panel.component.html",
    styleUrls: ["./loading-panel.component.scss"]
})
export class LoadingPanelComponent {
    isBusy: Observable<boolean>;

    constructor(public loading: LoadingService) {
        this.isBusy = loading.isBusy.pipe(debounce(event => interval(event ? 0 : 200)));
    }
}
