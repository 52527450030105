import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";

import { ILanguageModel, IOrganizationManagementModel } from "../../interfaces/api-models";
import { BaseComponent } from "../components/base.component";
import { LocalUserSettingsService } from "../services/local-user-settings.service";
import { ModalService } from "../services/modal.service";
import { AddNewLanguageComponent } from "./add-new-language.component";
import { AddLanguageLibraryComponent } from "./add-language-library.component";
import { NotificationService } from "app/services/notification.service";
import { UserAccessService } from "app/services/user-access.service";
import { MessageBoxService } from "common.module/components/message-box";
import { TranslationApiService } from "app/services/translation.api.service";
import { MatSort } from "@angular/material/sort";

@Component({
    selector: "translation-management",
    styleUrls: ["translation-management.component.scss"],
    templateUrl: "./translation-management.component.html",
    animations: [
    trigger("detailExpand", [
      state("collapsed", style({height: "0px", minHeight: "0"})),
      state("expanded", style({height: "*"})),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class TranslationManagementComponent extends BaseComponent implements OnInit {

    dataSource = new MatTableDataSource<IOrganizationManagementModel>();
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    expandedElement: any = null;
    columns = [ "name", "languages", "buttons"];
    translations!: IOrganizationManagementModel[];
    isLoading = false;

    constructor(
        private readonly translationApiService: TranslationApiService,
        private readonly userSettingsService: LocalUserSettingsService,
        private readonly modalService: ModalService,
        private readonly notificationService: NotificationService,
        private readonly userAccessService: UserAccessService,
        private readonly messageBoxService: MessageBoxService) {
        super();
    }

    async ngOnInit() {
        const userSettings = this.userSettingsService.getSettings();
        this.paginator.pageSize = userSettings.translationManagemntPageSize;

        this.translations = [];
        this.dataSource = new MatTableDataSource<IOrganizationManagementModel>(this.translations);
    }

    async ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
            this.initOrganizationLanguage();
        });

        setTimeout(() => this.initOrganizationLanguage());
    }

    get isSystemAdmin() {
        return this.userAccessService.isSystemAdmin();
    }

    get currentSize() {
        return this.paginator.pageIndex * this.paginator.pageSize;
    }

    async openAddNewLanguageModal(org: IOrganizationManagementModel) {
        const obj = {
            organizationId: org.id,
            languageId: undefined,
        };
        const actionCommitted = await this.modalService.openModalFromTemplate<boolean>(AddNewLanguageComponent, obj);
        if (actionCommitted) {
            await this.initOrganizationLanguage(this.currentSize);
        }
    }

    async openAddLanguageLibraryModal(org: IOrganizationManagementModel) {
        await this.modalService.openModalFromTemplate<boolean>(AddLanguageLibraryComponent, org);
        await this.initOrganizationLanguage(this.currentSize);
    }

    async openEditLanguageLibraryModal(language: ILanguageModel, org: IOrganizationManagementModel) {
        const obj = {
            organizationId: org.id,
            languageId: language.id,
            languageName: language.displayName,
            code: language.code,
            isEdit: true,
        };

        const actionCommitted = await this.modalService.openModalFromTemplate<boolean>(AddNewLanguageComponent, obj);
        if (actionCommitted) {
            await this.initOrganizationLanguage(this.currentSize);
        }
    }

    async syncToADE(language: ILanguageModel, organization: string) {
        const result = await this.translationApiService.syncToADE(language.id);

        if (result?.succeeded) {
            let notification = `${this.resources.Translation.LanguageSynced}. ${organization}: ${language.displayName}`;
            this.notificationService.showNotification([notification], true);
            await this.initOrganizationLanguage(this.currentSize);
        } else {
            this.notificationService.showNotification(result?.errors, false);
        }
    }

    async syncAllLanguages() {
        const confirm = await this.messageBoxService.confirm(this.resources.Account.ProceedConfirmation, this.resources.Translation.SyncAllLanguages);
        if (confirm) {
            const result = await this.translationApiService.syncAllLanguagesToADE(false);
            if (result?.succeeded) {
                this.notificationService.showNotification([this.resources.Translation.LanguageSynced], true);
                await this.initOrganizationLanguage(this.currentSize);
            } else {
                this.notificationService.showNotification(result?.errors, false);
            }
        }
    }

    pageChanged(event: PageEvent) {
        this.userSettingsService.changeState("translationManagemntPageSize", event.pageSize);

        this.initOrganizationLanguage(event.pageIndex * event.pageSize);
    }

    private async initOrganizationLanguage(currentSize: number = 0) {
        this.isLoading = true;
        const sort = this.getSort();
        const data = await this.translationApiService.getOrganizationsWithLanguages(this.paginator.pageSize, currentSize, sort);

        if (data != null) {
            this.translations.length = currentSize;
            this.translations.push(...data.organizations);
            this.translations.length = data.totalCount;
    
            this.dataSource = new MatTableDataSource<IOrganizationManagementModel>(this.translations);
            this.dataSource.paginator = this.paginator;
        }

        this.isLoading = false;
    }

    private getSort() {
        if (this.sort == null || this.sort.direction == null || this.sort.direction === "") {
            return undefined;
        }

        return `${this.sort.active}|${this.sort.direction}`;
    }
}