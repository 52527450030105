import { Injectable, EventEmitter } from "@angular/core";
import { first } from "rxjs/operators";

import { AuthorizeService, IUser } from "../../api-authorization/authorize.service";

@Injectable({
    providedIn: "root"
})

export class UserAccessService {

    user?: IUser;

    userInitialized = new EventEmitter<IUser>();

    constructor(private authorizeService: AuthorizeService) {
        this.authorizeService.getUser().pipe(first(x => x?.sub != null)).subscribe(x => {
            this.user = x ?? undefined;
            this.userInitialized.emit(this.user);
        });
    }

    isCurrentUser(userId: string) {
        return this.user && this.user!.sub === userId;
    }

    isRegularUser() {
        return this.user && this.user.role === "User";
    }

    isAdmin() {
        return this.user && (this.user.role === "Organization Admin" || this.user.role === "System Admin");
    }

    isSystemAdmin() {
        return this.user && this.user.role === "System Admin";
    }

    haveAccessToAllSameOrganizations(organizationIds: string[]) {
        for (const organizationId of organizationIds) {
            const isHaveAccess = this.haveAccessToOrganization(organizationId);
            if (!isHaveAccess) {
                return false;
            }
        }
        return true;
    }

    haveAccessToAnyOrganizations(organizationIds: string[]) {
        for (const organizationId of organizationIds) {
            const isHaveAccess = this.haveAccessToOrganization(organizationId);

            if (isHaveAccess) {
                return true;
            }
        }
        return false;
    }

    haveAccessToOrganization(organizationId: string) {
        if (this.isSystemAdmin()) {
            return true;
        }

        return this.getRelatedOrganization().indexOf(organizationId) > -1;
    }

    private getRelatedOrganization() {
        return this.user && this.user.organizations ? this.user.organizations.split(";") : [];
    }
}
