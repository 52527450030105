import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";

import { IUser, OrganizationType } from "../../interfaces/api-models";
import { IUserSettings } from "../../interfaces/interfaces";
import { BaseComponent } from "../components/base.component";
import { AdminApiService } from "../services/admin.api.service";
import { LocalUserSettingsService } from "../services/local-user-settings.service";
import { ModalService } from "../services/modal.service";
import { AddNewUserComponent } from "./add-new-user.component";

@Component({
    selector: "user-management",
    styleUrls: ["user-management.component.scss"],
    templateUrl: "./user-management.component.html",
    animations: [
    trigger("detailExpand", [
      state("collapsed", style({height: "0px", minHeight: "0"})),
      state("expanded", style({height: "*"})),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class UserManagementComponent extends BaseComponent implements OnInit {

    dataSource = new MatTableDataSource<IUser>();
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    expandedElement: any = null;

    columns = [ "email", "organizationCount", "roles", "buttons"];

    constructor(
        private readonly adminApiService: AdminApiService,
        private readonly userSettingsService: LocalUserSettingsService,
        private readonly modalService: ModalService) {

        super();
    }

    async ngOnInit() {
        const userSettings = this.userSettingsService.getSettings();

        this.paginator.pageSize = userSettings.userManagementPageSize;
        this.dataSource.paginator = this.paginator;
        await this.initUsers();
    }

    pageChanged(event: PageEvent) {
        this.userSettingsService.changeState("userManagementPageSize", event.pageSize);
    }

    async openAddNewUserModal() {
        const actionCommitted = await this.modalService.openModalFromTemplate<boolean>(AddNewUserComponent);
        if (actionCommitted) {
            await this.initUsers();
        }
    }

    formatRoles(user: IUser) {
        if (user.roles == null || user.roles.length === 0) {
            return "";
        }
        return user.roles.map(x => x.name).join(",");
    }

    formatOrganizationType(type: OrganizationType) {
        if (type === OrganizationType.CloudProvider) {
            return this.resources.Organization.CloudProvider;
        }
        if (type === OrganizationType.FarmSoftware) {
            return this.resources.Organization.FarmSoftware;
        }

        return this.resources.Organization.MilkRecordingOrganization;
    }

    private async initUsers() {
        this.dataSource.data = await this.adminApiService.getUsers() ?? [];
    }
}
