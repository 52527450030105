import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { INotificationData } from "../../interfaces/api-models";

@Component({
    selector: "notification-component",
    templateUrl: "./notification.component.html",
    styleUrls: ["./notification.component.scss"]
})
export class NotificationComponent {

    data = {} as INotificationData;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public inputData: INotificationData) {
        this.data = inputData;
    }
}
