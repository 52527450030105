<mat-icon class="close-dialog" mat-icon-button (click)="closeModal()">close</mat-icon>
<h2 mat-dialog-title>{{resources.Account.ManageOrganizationType}}</h2>
<mat-dialog-content class="mat-typography">
    <mat-paginator [pageSizeOptions]="[5, 10, 30, 50]" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
    <table mat-table matSort multiTemplateDataRows [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="organizationTypeName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.OrganizationType.Name}}</th>
            <td mat-cell *matCellDef="let org">{{org.name}}</td>
        </ng-container>

        <ng-container matColumnDef="organizationTypeTitle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.OrganizationType.Title}}</th>
            <td mat-cell *matCellDef="let org">{{org.title}}</td>
        </ng-container>

        <ng-container matColumnDef="organizationTypeAcronym">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.OrganizationType.Acronym}}</th>
            <td mat-cell *matCellDef="let org">{{org.acronym}}</td>
        </ng-container>

        <ng-container matColumnDef="update">
            <th mat-header-cell *matHeaderCellDef>{{resources.Common.Update}}</th>
            <td mat-cell *matCellDef="let org">
                <button mat-icon-button color="primary" (click)="editOrganizationTypeModal(org)">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>{{resources.Common.Delete}}</th>
            <td mat-cell *matCellDef="let org">
                <button mat-icon-button color="warn" (click)="DeleteOrganizationType(org)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns" class="element-row"></tr>
    </table>
    <br />
    <h2>{{resources.Account.AddOrganizationType}}</h2>
    <form [formGroup]="form" autocomplete="off">
        <mat-form-field appearance="fill">
            <mat-label>{{resources.OrganizationType.Name}}</mat-label>
            <input matInput [(ngModel)]="newOrganizationType.name" formControlName="name" required>
            <mat-icon matSuffix></mat-icon>
            <mat-error *ngIf="!isFieldValid('name')">{{getErrorMessage('name')}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.OrganizationType.Title}}</mat-label>
            <input matInput [(ngModel)]="newOrganizationType.title" formControlName="title" required>
            <mat-icon matSuffix></mat-icon>
            <mat-error *ngIf="!isFieldValid('title')">{{getErrorMessage('title')}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.OrganizationType.Acronym}}</mat-label>
            <input matInput [(ngModel)]="newOrganizationType.acronym" formControlName="acronym" required>
            <mat-icon matSuffix></mat-icon>
            <mat-error *ngIf="!isFieldValid('acronym')">{{getErrorMessage('acronym')}}</mat-error>
        </mat-form-field>
        <br>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="clearForm()">{{resources.Common.Clear}}</button>
    <button mat-raised-button [disabled]="form.invalid" cdkFocusInitial (click)="changeOrganizationType()">
        {{ isEdit ? resources.Common.Update : resources.Common.Add}}
    </button>
</mat-dialog-actions>
