import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthorizeService, AuthenticationResultStatus } from "./authorize.service";
import { mergeMap, tap } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class AuthorizeInterceptor implements HttpInterceptor {
    constructor(private authorize: AuthorizeService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authorize.getAccessToken()
            .pipe(mergeMap(token => this.processRequestWithToken(token, req, next)));
    }

    // Checks if there is an access_token available in the authorize service
    // and adds it to the request in case it's targeted at the same origin as the
    // single page application.
    private processRequestWithToken(token: string | null, req: HttpRequest<any>, next: HttpHandler) {
        if (!!token && this.isSameOriginUrl(req)) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(req).pipe(
            tap(// Succeeds when there is a response; ignore other events
                event => { },
                // Operation failed; error is an HttpErrorResponse
                async error => {
                    if (error instanceof HttpErrorResponse) {
                        const errorResponse = error as HttpErrorResponse;
                        if (errorResponse.status === 401) {
                            try {
                                const returnUrl = this.authorize.getReturnUrl();
                                const result = await this.authorize.signIn({ returnUrl });
                                switch (result.status) {
                                    case AuthenticationResultStatus.Success:
                                        await this.authorize.navigateToReturnUrl(returnUrl);
                                        break;
                                    default:
                                        throw new Error("Failed to sing in silently.");
                                }
                            } catch(ex) {
                                //this.authorize.
                            }
                        }
                    }
                }));
    }

    private isSameOriginUrl(req: any) {
        // It's an absolute url with the same origin.
        if (req.url.startsWith(`${window.location.origin}/`)) {
            return true;
        }

        // It's a protocol relative url with the same origin.
        // For example: //www.example.com/api/Products
        if (req.url.startsWith(`//${window.location.host}/`)) {
            return true;
        }

        // It's a relative url like /api/Products
        if (/^\/[^\/].*/.test(req.url)) {
            return true;
        }

        // It's an absolute or protocol relative url that
        // doesn't have the same origin.
        return false;
    }
}
