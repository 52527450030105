<div class="container">
    <div class="topContainer">
        <organization-summary *ngIf="organization" [organization]="organization"></organization-summary>
        <chart-component *ngIf="organization" [series]="series" [xAxis]="hours"></chart-component>
    </div>
    
    <div class="latestLogs" *ngIf="organization">
        <h4>{{resources.Log.LatestLogs}}</h4>
        <log-table [organization]="organization" [inputFilters]="filterLatest" [disableFilters]="true" [hidePaginator]="true"></log-table>
    </div>
    <div class="failedLogs" *ngIf="organization">
        <h4>{{resources.Log.FailedLogs}}</h4>
        <log-table [organization]="organization" [inputFilters]="filterFailed" [disableFilters]="true" [hidePaginator]="true"></log-table>
    </div>
</div>
<loading></loading>
