import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { finalize, delay } from "rxjs/operators";
import { LoadingService } from "./loading.service";

@Injectable({
    providedIn: "root"
})
export class LoadingInterceptor implements HttpInterceptor {
    constructor(private loading: LoadingService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loading.isBusy.next(true);

        return next.handle(req).pipe(delay(200),
            finalize(() => {
                this.loading.isBusy.next(false);
            }));
    }
}
