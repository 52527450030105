import { Component, OnInit } from "@angular/core";
import { IOrganization } from "../../interfaces/api-models";
import { OrganizationsApiService } from "../services/organizations.api.service";
import { HomeComponent } from "app/home/home.component";
import { trigger, style, animate, transition } from '@angular/animations';
import { Direction } from "src/interfaces/interfaces";

@Component({
    selector: "overall-status",
    templateUrl: "./overall.component.html",
    styleUrls: ["./overall.component.scss"],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateX({{enterDirection}}%)' }),
                animate('{{ duration }} ease-out', style({ transform: 'translateX(0%)' }))
            ], { params: { enterDirection: 100, duration: '300ms' } }),

            transition(':leave', [
                animate('{{ duration }} ease-in', style({ transform: 'translateX({{leaveDirection}}%)' }))
            ], { params: { leaveDirection: -100, duration: '300ms' } })
        ])
    ]
})
export class OverallStatusComponent implements OnInit {

    organizations: IOrganization[] = [];
    currentPage = 1;
    itemsPerPage = 4;
    totalItems = 0;

    enterDirection = 100;
    leaveDirection = -100;

    Direction = Direction;
    organizationsCache: { [key: number]: any } = {};
    
    constructor(private readonly organizationsApi: OrganizationsApiService) { }

    async ngOnInit() {
        this.organizationsCache = {};
        await this.loadOrganizations(0);
        await this.updateHomeComponentOrganizations();
    }

    async loadOrganizations(skip: number): Promise<void> {

        if (this.organizationsCache[skip]) {
            const cachedData = this.organizationsCache[skip];
            this.organizations = cachedData.organizations;
            this.totalItems = cachedData.totalCount;
            return;
        }

        const data = await this.organizationsApi.getWithOutChartData(this.itemsPerPage, skip);
        if (data) {
            this.organizationsCache[skip] = {
                organizations: data.organizations,
                totalCount: data.totalCount,
            };
            this.organizations = data.organizations;
            this.totalItems = data.totalCount;
        }
    }

    async updateHomeComponentOrganizations(): Promise<void> {
        if (!HomeComponent.organizations.length) {
            HomeComponent.organizations = await this.organizationsApi.getSimplifiedOrganizations() ?? [];
        }
    }

    changePage(direction: Direction): void {
        const newPage = this.currentPage + (direction === 'next' ? 1 : -1);

        if (newPage > 0 && newPage <= this.totalPages()) {
            this.enterDirection = direction === 'next' ? 100 : -100;
            this.leaveDirection = direction === 'next' ? -100 : 100;
            this.currentPage = newPage;
            const skip = (this.currentPage - 1) * this.itemsPerPage;
            this.loadOrganizations(skip);
        }
    }

    totalPages(): number {
        return Math.ceil(this.totalItems / this.itemsPerPage);
    }

    progressValue(): number {
        return (this.currentPage / this.totalPages()) * 100;
    }
}
