import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { ActivatedRoute, Router } from "@angular/router";
import { IOrganization, IRole, IUser } from "../../interfaces/api-models";
import { BaseComponent } from "../components/base.component";
import { ChangePasswordComponent } from "../components/change-password.component";
import { AdminApiService } from "../services/admin.api.service";
import { ModalService } from "../services/modal.service";
import { NotificationService } from "../services/notification.service";
import { OrganizationsApiService } from "../services/organizations.api.service";
import { UserAccessService } from "../services/user-access.service";

@Component({
    selector: "account",
    styleUrls: ["user-detail.component.scss"],
    templateUrl: "./user-detail.component.html",
})
export class UserDetailComponent extends BaseComponent implements OnInit {

    user!: IUser;
    roles: IRole[] = [];
    organizations: IOrganization[] = [];
    selectedOrganizations: IOrganization[] = [];

    email: string = "";

    form = this.formBuilder.group({
        email: [, { validators: [Validators.required, Validators.email] }],
        role: [, { validators: [Validators.required], disabled: true }],
        organization: [, { validators: []}]
    });

    constructor(
        private readonly adminService: AdminApiService,
        private readonly route: ActivatedRoute,
        private readonly organizationService: OrganizationsApiService,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly notificationService: NotificationService,
        private readonly userAccessService: UserAccessService,
        private readonly formBuilder: FormBuilder) {
        super();
    }

    async ngOnInit() {
        const userId = this.route.snapshot.params["userId"];

        var user = await this.adminService.getUser(userId);
        if (user != null) {
            this.user = user;
            this.email = this.user.email;

            this.selectedOrganizations = this.user.organizationModels;

            this.form.get("email")?.disable();

            if (!this.isAdmin || this.isCurrentUser || !this.haveAccessToAllSameOrganizations) {
                this.form.get("role")?.disable();
            } else {
                this.roles = await this.adminService.getRoles() ?? [];
            }

            if (!this.isAdmin || this.user.roles[0].name === "System Admin" || this.isCurrentUser) {
                this.form.get("organization")?.disable();
            }
        }
    }

    get disableSave() {
        return !this.isAdmin || this.isCurrentUser;
    }

    get haveAccessToAllSameOrganizations() {
        return this.userAccessService.haveAccessToAllSameOrganizations(this.selectedOrganizations.map(x => x.id));
    }

    get isCurrentUser() {
        return this.userAccessService.isCurrentUser(this.user.id);
    }

    get isUser() {
        return this.userAccessService.isRegularUser();
    }

    get isAdmin() {
        return this.userAccessService.isAdmin();
    }

    get isSystemAdmin() {
        return this.userAccessService.isSystemAdmin();
    }

    get canRemoveOrganization() {
        return this.isSystemAdmin || this.isAdmin && !this.isCurrentUser;
    }

    async deleteUser() {
        const confirm = await this.modalService.openConfirmDialog(this.resources.Account.DeleteConfirmation);
        if (confirm) {

            await this.adminService.deleteUser(this.user.id);
            this.notificationService.showNotification([this.resources.Account.UserDeleted], true);

            this.router.navigate(["/user-management"]);
        }
    }

    async updateUser() {
        if (this.user.phoneNumber == null) {
            this.user.phoneNumber = "";
        }
        const result = await this.adminService.updateUser(this.user);

        if (result?.succeeded) {
            this.email = this.user.email;
            this.notificationService.showNotification([this.resources.Account.UserUpdated], true);

            if (!this.isAdmin || this.user.roles[0].name === "System Admin") {
                this.form.get("organization")?.disable();
            } else {
                this.form.get("organization")?.enable();
            }
            if (this.user.roles[0].name === "System Admin") {
                this.selectedOrganizations = [];
                this.form.controls["organization"].setValue(null);
            }

        } else {
            this.notificationService.showNotification(result?.errors, false);
        }
    }

    async changePassword() {
        await this.modalService.openModalFromTemplate(ChangePasswordComponent, this.user.id);
    }

    formatRole(role: IRole) {
        return role ? role.name : "";
    }

    formatOrganization(organization: IOrganization) {
        return organization?.name ?? "";
    }

    onOrganizationSelected(event: MatAutocompleteSelectedEvent) {
        const organizationToAdd = event.option.value as IOrganization;

        if (this.selectedOrganizations.findIndex(x => x.identifier === organizationToAdd.identifier) === -1) {
            this.selectedOrganizations.push(organizationToAdd);
        }

        this.form.controls["organization"].setValue(null);
    }

    removeOrganization(organization: IOrganization) {
        if (this.userAccessService.haveAccessToOrganization(organization.id)) {
            const index = this.selectedOrganizations.findIndex(x => x.identifier === organization.identifier);

            if (index > -1) {
                this.selectedOrganizations.splice(index, 1);
            }

            if (this.selectedOrganizations.length === 0) {
                this.selectedOrganizations = [];
                this.form.controls["organization"].setValue(null);
            }
        }
    }

    async onKeyUp(keyboardEvent: any, type: "client" | "dataProvider") {
        const query = keyboardEvent.target.value;

        if (query == null || query.length < 3) {
            this.organizations = [];
        } else {
            this.organizations = await this.organizationService.findByName(query) ?? [];
        }
    }
}
