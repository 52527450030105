<div class="wrapper">
	<app-nav-menu></app-nav-menu>
	<div class="content-large">
	    <router-outlet></router-outlet>
	</div>
	<loading></loading>
	<footer>
		<div class="content-large">
			<hr>
			<div class="copyright">
				© iDDEN - International dairy data exchange network 2020. All rights reserved.
			</div>
		</div>
	</footer>
</div>
