<h2 mat-dialog-title>{{resources.Translation.AddLanguage}}</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
		<mat-form-field appearance="fill">
			<mat-label>{{resources.Translation.iDDENLibrary}}</mat-label>
			<mat-select [(ngModel)]="selectedLibrary" (selectionChange)="onLanguageSelection($event)">
			    <mat-option  *ngFor="let lib of libraryLanguages" [value]="lib">
                    {{lib.displayName}} - {{lib.code}}
                </mat-option>
			</mat-select>
		  </mat-form-field>
	</div>
	<mat-paginator [pageSizeOptions]="[10, 30, 50]" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
	<table mat-table matSort multiTemplateDataRows [dataSource]="dataSource" matSortActive="createdAt" matSortDisableClear matSortDirection="desc" class="mat-elevation-z8">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>{{resources.Common.Id}}</th>
            <td mat-cell *matCellDef="let lib">{{lib.id}}</td>
        </ng-container>

		<ng-container matColumnDef="displayName">
            <th  mat-sort-header mat-header-cell *matHeaderCellDef>{{resources.Translation.Language}}</th>
            <td mat-cell *matCellDef="let lib" class="display-name">{{lib.displayName}} - {{lib.code}}</td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>{{resources.Common.Update}}</th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button color="primary" (click)="editSelectedLanguage(row)">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>{{resources.Common.Delete}}</th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button color="warn" (click)="deleteSelectedLanguage(row)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns" class="element-row"></tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>{{resources.Common.Cancel}}</button>
</mat-dialog-actions>