<h2 mat-dialog-title>{{resources.Account.SendEmailToOrganizations}}</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="form" autocomplete="off">
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Account.Organizations}}</mat-label>
            <input type="text"
                   formControlName="organization"
                   placeholder="Choose organizations"
                   matInput
                   name="organization"
                   (keyup)="onKeyUp($event)"
                   [matAutocomplete]="clientOrganizationAutocomplete">
            <mat-autocomplete #clientOrganizationAutocomplete="matAutocomplete" [displayWith]="formatOrgatization" (optionSelected)="onOrganizationSelected($event)">
                <mat-option [value]="selectAllOption">Select all organizations</mat-option>
                <mat-option *ngFor="let organization of organizations" [value]="organization">
                    {{formatOrgatization(organization)}}
                </mat-option>
            </mat-autocomplete>
            <mat-icon matSuffix></mat-icon>
        </mat-form-field>
        <mat-form-field *ngIf="selectedOrganizations.length > 0">
            <mat-chip-list [multiple]="true">
                <mat-chip *ngFor="let organization of selectedOrganizations" selected
                          removable (removed)="removeOrganization(organization)">
                    {{formatOrgatization(organization)}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
            <mat-icon matSuffix></mat-icon>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Organization.Subject}}</mat-label>
            <input matInput [(ngModel)]="emailContent.subject" formControlName="subject" required>
            <mat-icon matSuffix></mat-icon>
            <mat-error *ngIf="!isFieldValid('subject')">{{getErrorMessage('subject')}}</mat-error>
        </mat-form-field>
        <angular-editor class="email-box" matInput [(ngModel)]="emailContent.message" formControlName="message" [config]="editorConfig"></angular-editor>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{resources.Common.Cancel}}</button>
    <button mat-button [disabled]="this.isLoading || this.form.invalid" (click)="sendEmails()">{{resources.Common.Send}}</button>
</mat-dialog-actions>
