<h2 mat-dialog-title>{{resources.Account.AddUser}}</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="form" autocomplete="off">
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Account.Email}}</mat-label>
            <input matInput [(ngModel)]="newUser.email" formControlName="email" required>
            <mat-icon matSuffix>email</mat-icon>
            <mat-error *ngIf="!isFieldValid('email')">{{getErrorMessage('email')}}</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Account.Role}}</mat-label>
                <input type="text"
                       formControlName="role"
                       placeholder="Choose role"
                       matInput
                       name="role"
                       required 
                       [(ngModel)]="selectedRole"
                       [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="formatRole" (optionSelected)="onRoleSelected($event)">
                    <mat-option *ngFor="let role of roles" [value]="role">
                        {{role.name}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix></mat-icon>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>{{resources.Account.Organizations}}</mat-label>
            <input type="text"
                   formControlName="organization"
                   placeholder="Choose organizations"
                   matInput
                   name="clientOrganization"
                   required
                   (keyup)="onKeyUp($event, 'client')"
                   [matAutocomplete]="clientOrganizationAutocomplete">
            <mat-autocomplete #clientOrganizationAutocomplete="matAutocomplete" [displayWith]="formatOrgatization" (optionSelected)="onOrganizationSelected($event)">
                <mat-option *ngFor="let organization of organizations" [value]="organization">
                    {{formatOrgatization(organization)}}
                </mat-option>
            </mat-autocomplete>
            <mat-icon matSuffix></mat-icon>
        </mat-form-field>
        <br>
        <mat-form-field *ngIf="selectedOrganizations.length > 0">
            <mat-chip-list [multiple]="true">
                <mat-chip *ngFor="let organization of selectedOrganizations" selected
                          removable (removed)="removeOrganization(organization)">
                    {{formatOrgatization(organization)}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
            <mat-icon matSuffix></mat-icon>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{resources.Common.Cancel}}</button>
    <button mat-button [disabled]="form.invalid" cdkFocusInitial (click)="addNewUser()">{{resources.Common.Add}}</button>
</mat-dialog-actions>
