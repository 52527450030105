import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";

import { resources } from "app/resources/resources";
import { ParameterApiService } from "src/app/services/parameter.api.service";
import { ILogStatistic } from "src/interfaces/api-models";
import { AggregationType, RangeType, Status, Perspective, IDropdownOption } from "src/interfaces/interfaces";

import * as moment from "moment";

@Injectable()
export class ReportService {

    resources = resources.data;

    private dateRanges: IDropdownOption[] = [
        {key: RangeType.Hour, value: this.resources.Statistics.Range.LastHour},
        {key: RangeType.Hour8, value: this.resources.Statistics.Range.LastHour8},
        {key: RangeType.Day, value: this.resources.Statistics.Range.LastDay},
        {key: RangeType.Week, value: this.resources.Statistics.Range.LastWeek},
        {key: RangeType.Month, value: this.resources.Statistics.Range.LastMonth},
        {key: RangeType.Year, value: this.resources.Statistics.Range.LastYear},
    ];

    private aggregations: IDropdownOption[] = [
        {key: AggregationType.Minutes15, value: this.resources.Statistics.Aggregation.ByMinutes15},
        {key: AggregationType.Minutes30, value: this.resources.Statistics.Aggregation.ByMinutes30},
        {key: AggregationType.Hour, value: this.resources.Statistics.Aggregation.ByHour},
        {key: AggregationType.Hour8, value: this.resources.Statistics.Aggregation.ByHour8},
        {key: AggregationType.Day, value: this.resources.Statistics.Aggregation.ByDay},
        {key: AggregationType.Week, value: this.resources.Statistics.Aggregation.ByWeek},
        {key: AggregationType.Month, value: this.resources.Statistics.Aggregation.ByMonth},
    ];

    private statuses: IDropdownOption[] = [
        {key: Status.All, value: "All"},
        {key: Status.Success, value: "Success"},
        {key: Status.Error, value: "Error"}
    ];

    private perspectives: IDropdownOption[] = [
        {key: Perspective.Client, value: "Client"},
        {key: Perspective.DataProvider, value: "DataProvider"}
    ];

    private allowedAggregationTypes: { [key in RangeType] : AggregationType[]} = {} as any;

    constructor(private readonly parameterService: ParameterApiService) {
        this.allowedAggregationTypes[RangeType.Hour] = [AggregationType.Minutes15, AggregationType.Minutes30];
        this.allowedAggregationTypes[RangeType.Hour8] = [AggregationType.Minutes30, AggregationType.Hour];
        this.allowedAggregationTypes[RangeType.Day] = [AggregationType.Hour, AggregationType.Hour8];
        this.allowedAggregationTypes[RangeType.Week] = [AggregationType.Hour8, AggregationType.Day];
        this.allowedAggregationTypes[RangeType.Month] = [AggregationType.Day, AggregationType.Week];
        this.allowedAggregationTypes[RangeType.Year] = [AggregationType.Week, AggregationType.Month];
    }

    getDateRanges() {
        return this.dateRanges;
    }

    getAggregationTypes() {
        return this.aggregations;
    }

    getLogStatuses() {
        return this.statuses;
    }

    getPerspectives() {
        return this.perspectives;
    }

    getLogTypesAsync() {
        return this.parameterService.getMessageTypes();
    }

    getAllowedAggregationTypes() {
        return this.allowedAggregationTypes;
    }

    filterLogByStatus(log: ILogStatistic, status: Status) {
        if (status === Status.Error) {
            return !(log.status === 200 || log.status === 0) || log.status == null;
        } else if (status === Status.Success) {
            return log.status === 200 || log.status === 0;
        }

        return true;
    }

    filterLogByPerspective(log: ILogStatistic, selectedOrganizations: string[], perspective: Perspective) {
        if (perspective === Perspective.Client) {
            return selectedOrganizations.includes(log.clientOrganizationId);
        }

        return selectedOrganizations.includes(log.dataProviderOrganizationId);
    }

    filterLogByMessageType(log: ILogStatistic, messageTypes: number[]) {
        return messageTypes.includes(log.messageType);
    }

    groupLog(log: ILogStatistic, perspective: Perspective) {
        if (perspective === Perspective.Client) {
            return log.clientOrganization;
        }

        return log.dataProviderOrganization;

    }

    getSelectedCount(array: string[] | number[]) {
        if (array.length === 0) {
            return "";
        }

        return "(" + array.length + ")";
    }

}