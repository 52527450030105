<div class="table">
<div class="activeFilters" [style.display]="filters.activeCount > 0? 'block' : 'none'">

    <mat-form-field class="no-underline" *ngIf="selectedClientOrganization">
        <mat-chip-list [multiple]="false">
            <mat-chip color="primary" selected
                      removable (removed)="removeSelectedClientOrganization()">
                {{resources.Log.ClientFilterValuePrefix}} {{formatOrganization(selectedClientOrganization)}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </mat-form-field>

    <mat-form-field class="no-underline" *ngIf="selectedDataProviderOrganization">
        <mat-chip-list [multiple]="false">
            <mat-chip color="primary" selected
                      removable (removed)="removeSelectedDataProviderOrganization()">
                {{resources.Log.DataProviderFilterValuePrefix}} {{formatOrganization(selectedDataProviderOrganization)}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </mat-form-field>

    <mat-form-field class="no-underline" *ngIf="filters.locationId.value!= null">
        <mat-chip-list [multiple]="false">
            <mat-chip color="primary" selected
                      removable (removed)="removeSelectedLocationId()">
                {{resources.Log.LocationId}} {{filters.locationId.value}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </mat-form-field>

    <mat-form-field class="no-underline" *ngIf="filters.statusCode.values && filters.statusCode.values.length > 0">
        <mat-chip-list [multiple]="true">
            <mat-chip color="primary"
                      selected removable (removed)="removeStatusFilter()">
                {{resources.Log.StatusCodeFilterValuePrefix}} {{getStatusName(filters.statusCode.values)}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </mat-form-field>

    <mat-form-field class="no-underline" *ngIf="filters.messageType.values && filters.messageType.values.length > 0">
        <mat-chip-list [multiple]="true">
            <mat-chip color="primary"
                      selected removable (removed)="removeMessageTypeFilter()">
                {{resources.Log.MessageTypeFilterValuePrefix}} {{getMessageTypeName(filters.messageType.values)}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </mat-form-field>

    <mat-form-field class="no-underline" *ngIf="filters.date.from || filters.date.to">
        <mat-chip-list [multiple]="false">
            <mat-chip color="primary"
                      selected removable (removed)="removeDateRangeFilter()">
                {{formatDate(filters.date.from, false) + " - " + formatDate(filters.date.to, false)}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </mat-form-field>
</div>
    
<mat-paginator [hidden]="hidePaginator" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons (page)="pageChanged($event)" [disabled]="isLoading"></mat-paginator>
<table mat-table matSort [dataSource]="dataSource" matSortActive="createdAt" matSortDisableClear matSortDirection="desc" class="mat-elevation-z8">

    <!-- columns -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.Common.Id}}</th>
        <td mat-cell *matCellDef="let log"><a mat-button color="primary" *ngIf="!isLoading" [disabled]="isLoading" [routerLink]="['/logs', log.id]">{{log.id}}</a></td>
    </ng-container>

    <ng-container matColumnDef="messageType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div>{{resources.Log.MessageType}}</div>
            <button *ngIf="!disableFilters" mat-icon-button [matMenuTriggerFor]="messageTypeFilter" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                <mat-icon>filter_alt</mat-icon>
            </button>
        </th>
        <td mat-cell *matCellDef="let log"><p *ngIf="!isLoading">{{log.messageType}}</p></td>
    </ng-container>

    <ng-container matColumnDef="locationId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div>{{resources.Log.LocationId}}</div>
            <button *ngIf="!disableFilters" mat-icon-button [matMenuTriggerFor]="locationIdFilter" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                <mat-icon>filter_alt</mat-icon>
            </button>
        </th>
        <td mat-cell *matCellDef="let log"><p *ngIf="!isLoading">{{log.locationId}}</p></td>
    </ng-container>

    <ng-container matColumnDef="clientOrganization">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="clientOrganization.Name">
            <div>{{resources.Log.ClientOrganization}}</div>
            <button *ngIf="!disableFilters" mat-icon-button [matMenuTriggerFor]="clientOrganizationFilter" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                <mat-icon>filter_alt</mat-icon>
            </button>
        </th>
        <td class="title-text" title="{{log.clientOrganizationName}}" mat-cell *matCellDef="let log">
            <a mat-button color="primary" *ngIf="!isLoading && hasAccess(log.clientOrganizationId)" [routerLink]="['/status', 'organization',  log.clientOrganizationId]">{{log.clientOrganizationName}}</a>
            <a *ngIf="!isLoading && !hasAccess(log.clientOrganizationId)">{{log.clientOrganizationName}}</a>
        </td>
    </ng-container>

    <ng-container matColumnDef="dataProviderOrganization">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="dataProviderOrganization.Name">
            <div>{{resources.Log.DataProviderOrganization}}</div>
            <button *ngIf="!disableFilters" mat-icon-button [matMenuTriggerFor]="dataProviderOrganizationFilter" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                <mat-icon>filter_alt</mat-icon>
            </button>
        </th>
        <td class="title-text" title="{{log.dataProviderOrganizationName}}" mat-cell *matCellDef="let log">
            <a mat-button color="primary" *ngIf="!isLoading && hasAccess(log.dataProviderOrganizationId)" [routerLink]="['/status', 'organization',  log.dataProviderOrganizationId]">{{log.dataProviderOrganizationName}}</a>
            <a *ngIf="!isLoading && !hasAccess(log.dataProviderOrganizationId)">{{log.dataProviderOrganizationName}}</a>
        </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div>{{resources.Common.CreatedAt}}</div>
            <button *ngIf="!disableFilters" mat-icon-button [matMenuTriggerFor]="createdAtFilter" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                <mat-icon>filter_alt</mat-icon>
            </button>
        </th>
        <td mat-cell *matCellDef="let log"><p *ngIf="!isLoading">{{formatDate(log.createdAt)}}</p></td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div>{{resources.Log.Status}}</div>
            <button *ngIf="!disableFilters"mat-icon-button [matMenuTriggerFor]="statusFilter" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                <mat-icon>filter_alt</mat-icon>
            </button>
        </th>
        <td mat-cell *matCellDef="let log">
            <p [ngStyle]="{'color': isErrorStatus(log.status) ? 'red' : 'green'}" *ngIf="!isLoading">{{log.status}}</p>
        </td>
    </ng-container>

    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.Log.Duration}}</th>
        <td mat-cell *matCellDef="let log"><p *ngIf="!isLoading">{{log.duration}}</p></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsNames; sticky:true"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsNames"></tr>
</table>

<!-- filters -->
<mat-menu #statusFilter="matMenu" xPosition="before" class="menu-form-wrapper">
    <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <form class="menu-form">
            <mat-form-field>
                <mat-select [formControl]="selectStatuses" [(ngModel)]="selectedStatuses" [placeholder]="resources.Log.StatusFilterPlaceholder" name="statuses" multiple (openedChange)="setStatusFilter()">
                    <app-select-all-option [control]="selectStatuses" [values]="statusCodes"></app-select-all-option>
                    <mat-divider></mat-divider>
                    <mat-option *ngFor="let option of statusCodes" [value]="option.key">{{option.value + " (" + option.key + ")"}}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-menu>

<mat-menu #messageTypeFilter="matMenu" xPosition="before" class="menu-form-wrapper">
    <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <form class="menu-form">
            <mat-form-field>
                <mat-select [formControl]="selectMessageTypes" [(ngModel)]="selectedMessageTypes" [placeholder]="resources.Log.MessageTypeFilterPlaceholder" name="messages" multiple (openedChange)="setMessageFilter()">
                    <app-select-all-option [control]="selectMessageTypes" [values]="messageTypes"></app-select-all-option>
                    <mat-divider></mat-divider>
                    <mat-option *ngFor="let option of messageTypes" [value]="option.key">{{option.value}}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-menu>

<mat-menu #locationIdFilter="matMenu" xPosition="before" class="menu-form-wrapper">
    <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <form class="menu-form">
            <mat-form-field>
                <input type="text"
                       [placeholder]="resources.Log.LocationIdPlaceholder"
                       matInput
                       name="locationId"
                       [(ngModel)]="locationIdQuery"
                       (keyup)="onKeyUp($event, 'locationId')"
                       [matAutocomplete]="locationIdAutocomplete">
                    <mat-autocomplete #locationIdAutocomplete="matAutocomplete" (optionSelected)="onLocationIdSelected($event)">
                        <mat-option *ngFor="let locationId of locationIds" [value]="locationId">
                            {{locationId}}
                        </mat-option>
                    </mat-autocomplete>
            </mat-form-field>
        </form>
    </div>
</mat-menu>

<mat-menu #dataProviderOrganizationFilter="matMenu" xPosition="before" class="menu-form-wrapper">
    <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <form class="menu-form">
            <mat-form-field>
                <input type="text"
                       [placeholder]="resources.Log.DataProviderFilterPlaceholder"
                       matInput
                       [(ngModel)]="dataProviderOrganizationQuery"
                       name="dataProviderOrganization"
                       (keyup)="onKeyUp($event, 'dataProvider')"
                       [matAutocomplete]="dataProviderOrganizationAutocomplete">
                <mat-autocomplete #dataProviderOrganizationAutocomplete="matAutocomplete" [displayWith]="formatOrganization" (optionSelected)="onDataProviderOrganizationSelected($event)">
                    <mat-option *ngFor="let organization of dataProviderOrganizations" [value]="organization">
                        {{formatOrganization(organization)}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </div>
</mat-menu>

<mat-menu #clientOrganizationFilter="matMenu" xPosition="before" class="menu-form-wrapper">
    <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <form class="menu-form">
            <mat-form-field>
                <input type="text"
                       [placeholder]="resources.Log.ClientFilterPlaceholder"
                       matInput
                       [(ngModel)]="clientOrganizationQuery"
                       name="clientOrganization"
                       (keyup)="onKeyUp($event, 'client')"
                       [matAutocomplete]="clientOrganizationAutocomplete">
                <mat-autocomplete #clientOrganizationAutocomplete="matAutocomplete" [displayWith]="formatOrganization" (optionSelected)="onClientOrganizationSelected($event)">
                    <mat-option *ngFor="let organization of clientOrganizations" [value]="organization">
                        {{formatOrganization(organization)}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </div>
</mat-menu>

<mat-menu #createdAtFilter="matMenu" xPosition="before" class="menu-form-wrapper">
    <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <form class="menu-form">
            <mat-form-field appearance="fill">
                <mat-label>{{resources.Common.DateRangeFrom}}</mat-label>
                <input matInput [matDatepicker]="pickerFrom" [(ngModel)]="filters.date.from" ngDefaultControl name="createdAtFrom">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{resources.Common.DateRangeTo}}</mat-label>
                <input matInput [matDatepicker]="pickerTo" [(ngModel)]="filters.date.to" ngDefaultControl name="createdAtTo">
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
        </form>
    </div>
</mat-menu>
</div>
