<div *ngIf="user" class="container mat-typography">
    <h1>Profile {{email}}</h1>
    <div class="edit-panel">
        <div class="edit-info">
            <form [formGroup]="form" autocomplete="off">
                <mat-form-field appearance="fill">
                    <mat-label>{{resources.Account.Email}}</mat-label>
                    <input matInput [(ngModel)]="user.email" formControlName="email">
                    <mat-icon matSuffix>email</mat-icon>
                </mat-form-field>
                <br>
                <mat-form-field appearance="fill">
                <mat-label>{{resources.Account.Role}}</mat-label>
                    <input type="text"
                           formControlName="role"
                           placeholder="Choose role"
                           matInput
                           name="role"
                           required 
                           formControlName="role"
                           [(ngModel)]="user.roles[0]"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="formatRole">
                        <mat-option *ngFor="let role of roles" [value]="role">
                            {{role.name}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix></mat-icon>
                </mat-form-field>
                <br>
                <mat-form-field *ngIf="isAdmin" appearance="fill">
                    <mat-label>{{resources.Account.Organizations}}</mat-label>
                    <input type="text"
                           formControlName="organization"
                           placeholder="Choose organizations"
                           matInput
                           name="clientOrganization"
                           (keyup)="onKeyUp($event, 'client')"
                           [disabled]="true"

                           [matAutocomplete]="clientOrganizationAutocomplete">
                    <mat-autocomplete #clientOrganizationAutocomplete="matAutocomplete" [displayWith]="formatOrganization" (optionSelected)="onOrganizationSelected($event)">
                        <mat-option *ngFor="let organization of organizations" [value]="organization" >
                            {{formatOrganization(organization)}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix></mat-icon>
                </mat-form-field>
                <br *ngIf="isAdmin">
                <mat-form-field *ngIf="selectedOrganizations.length > 0">
                    <mat-chip-list [multiple]="true">
                        <mat-chip *ngFor="let organization of selectedOrganizations"
                         [removable]="canRemoveOrganization" (removed)="removeOrganization(organization)" >
                            {{formatOrganization(organization)}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <mat-icon matSuffix></mat-icon>
                </mat-form-field>
            </form>
        </div>
        <div class="button-panel">
            <button *ngIf="isCurrentUser" mat-raised-button (click)="changePassword()">{{resources.Account.ChangePassword}}</button>
            <button *ngIf="!isCurrentUser  && haveAccessToAllSameOrganizations" mat-raised-button color="warn" (click)="deleteUser()">{{resources.Account.DeleteUser}}</button>
        </div>
        <div class="button-panel">
        </div>
    </div>
    <div>
        <button mat-raised-button color="primary" [disabled]="form.invalid || disableSave" (click)="updateUser()">{{resources.Common.Save}}</button>
    </div>
</div>
