import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";

import { Status, AggregationType, RangeType } from "src/interfaces/interfaces";

import * as moment from "moment";

@Injectable()
export class DateUtils {

    constructor(private readonly datePipe: DatePipe) {
    }

    getDatesBetween(startDate: Date, endDate: Date, delta: AggregationType) {
        const dateArray = [];
        let currentDate = this.applyRangeFormat(endDate, delta);
        startDate = this.applyRangeFormat(startDate, delta);

        while (currentDate >= startDate) {
            dateArray.push(new Date (currentDate));
            currentDate = this.applyRangeDelta(currentDate, delta);
            currentDate = this.applyRangeFormat(currentDate, delta);
        }

        return dateArray.reverse();
    }

    getFromDate(rangeType: RangeType, selectedAggregation: AggregationType) {

        const now = this.applyRangeFormat(new Date(), selectedAggregation);

        switch (rangeType) {
            case RangeType.Hour: {
                now.setHours(now.getHours() - 1);
                return now;
            }
            case RangeType.Hour8: {
                now.setHours(now.getHours() - 8);
                return now;
            }
            case RangeType.Day: {
                now.setDate(now.getDate() - 1);
                return now;
            }
            case RangeType.Week: {
                now.setDate(now.getDate() - 7);
                return now;
            }
            case RangeType.Month: {
                now.setMonth(now.getMonth() - 1);
                return now;
            }
            case RangeType.Year: {
                now.setFullYear(now.getFullYear() - 1);
                return now;
            }
            default: {
                throw new Error("no such RangeType");
            }
        }
    }

    formatDate(date: Date, delta: AggregationType) {

        date = new Date(date);

        switch (delta) {
            case AggregationType.Minutes15: {
                let minutes = date.getMinutes();

                if (minutes > 45) {
                    minutes = 60;
                } else if (minutes > 30) {
                    minutes = 45;
                } else if (minutes > 15) {
                    minutes = 30;
                } else if (minutes > 0) {
                    minutes = 15;
                }

                date.setMinutes(minutes);

                return this.datePipe.transform(date, "dd-MM-yyyy HH:mm") ?? "";
            }
            case AggregationType.Minutes30: {

                let minutes = date.getMinutes();

                if (minutes > 30) {
                    minutes = 60;
                } else if (minutes > 0) {
                    minutes = 30;
                }

                date.setMinutes(minutes);

                return this.datePipe.transform(date, "dd-MM-yyyy HH:mm") ?? "";
            }
            case AggregationType.Hour: {
                return this.datePipe.transform(date, "dd-MM-yyyy HH") ?? "";
            }
            case AggregationType.Hour8: {
                let hours = date.getHours();

                if (hours > 16) {
                    hours = 24;
                } else if (hours > 8) {
                    hours = 16;
                } else if (hours > 0) {
                    hours = 8;
                }

                date.setHours(hours);
                return this.datePipe.transform(date, "dd-MM-yyyy HH") ?? "";
            }
            case AggregationType.Day: {
                return this.datePipe.transform(date, "MM-yyyy dd") ?? "";
            }
            case AggregationType.Week: {
                const weekNumber = moment(date).week();
                return this.datePipe.transform(date, "MM-yyyy ")! + weekNumber;
            }
            case AggregationType.Month: {
                return this.datePipe.transform(date, "yyyy MM") ?? "";
            }
            default : {
                throw new Error("no such AggregationType");
            }
        }
    }

    private applyRangeFormat(date: Date, delta: AggregationType) {
        const result = new Date(date);

        switch (delta) {
            case AggregationType.Minutes15:
            case AggregationType.Minutes30: {
                result.setSeconds(0);
                result.setMilliseconds(0);
                return result;
            }
            case AggregationType.Hour:
            case AggregationType.Hour8: {
                result.setMinutes(0);
                result.setSeconds(0);
                result.setMilliseconds(0);
                return result;
            }
            case AggregationType.Day:
            case AggregationType.Week: {
                result.setHours(0);
                result.setMinutes(0);
                result.setSeconds(0);
                result.setMilliseconds(0);
                return result;
            }
            case AggregationType.Month: {
                result.setDate(1);
                result.setHours(0);
                result.setMinutes(0);
                result.setSeconds(0);
                result.setMilliseconds(0);
                return result;
            }
            default : {
                throw new Error("no such AggregationType");
            }
        }
    }

    private applyRangeDelta(date: Date, delta: AggregationType) {
        const result = new Date(date);

        switch (delta) {
            case AggregationType.Minutes15: {
                result.setMinutes(result.getMinutes() - 15);
                return result;
            }
            case AggregationType.Minutes30: {
                result.setMinutes(result.getMinutes() - 30);
                return result;
            }
            case AggregationType.Hour: {
                result.setHours(result.getHours() - 1);
                return result;
            }
            case AggregationType.Hour8: {
                result.setHours(result.getHours() - 8);
                return result;
            }
            case AggregationType.Day: {
                result.setDate(result.getDate() - 1);
                return result;
            }
            case AggregationType.Week: {
                result.setDate(result.getDate() - 7);
                return result;
            }
            case AggregationType.Month: {
                result.setMonth(result.getMonth() - 1);
                return result;
            }
            default : {
                throw new Error("no such AggregationType");
            }
        }
    }
}