<div class="container">
    <button mat-raised-button (click)="openCopyFromMROModal()">{{resources.Translation.CopyLanguageFromMRO}}</button>
    <button mat-raised-button (click)="syncAllLanguages()">{{resources.Translation.SyncAllLanguages}}</button>
    <mat-paginator [pageSizeOptions]="[10, 30, 50]" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
    <table mat-table matSort multiTemplateDataRows [dataSource]="dataSource" matSortActive="createdAt" matSortDisableClear matSortDirection="desc" class="mat-elevation-z8">
        <ng-container matColumnDef="displayName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.Translation.Language}}</th>
            <td mat-cell *matCellDef="let row"><span *ngIf="!isLoading">{{row.code}} - {{row.displayName}}</span></td>
        </ng-container>

        <ng-container matColumnDef="organizationName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{resources.Translation.LanguageCopiedFromMRO}}</th>
            <td mat-cell *matCellDef="let row"><span *ngIf="!isLoading">{{row.organizationName}}</span></td>
        </ng-container>

        <ng-container matColumnDef="buttons">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button color="primary" (click)="openEditLanguageLibraryModal(row)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="deleteLanguageFromLibraryModal(row)">
                    <mat-icon>delete</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="syncToADE(row)" *ngIf="!isLoading"
                    [matTooltip]="row.lastSyncedAt ? resources.Translation.LastSynced + ': ' + (row.lastSyncedAt | date:'medium') : resources.Translation.SyncLanguage">
                    <mat-icon>autorenew</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns" class="element-row"></tr>
    </table>
</div>