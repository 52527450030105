<div *ngIf="organization" class="container mat-typography">
    <h1>{{resources.Organization.Identifier}} {{organization.identifier}}</h1>
    <div class="edit-panel">
        <div class="edit-info">
            <form [formGroup]="form" autocomplete="off">
                <mat-form-field appearance="fill">
                    <mat-label>{{resources.Organization.Name}}</mat-label>
                    <input matInput [(ngModel)]="organization.name" formControlName="name" required>
                    <mat-icon matSuffix></mat-icon>
                    <mat-error *ngIf="!isFieldValid('name')">{{getErrorMessage('name')}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>{{resources.Organization.Type}}</mat-label>
                    <mat-icon matSuffix></mat-icon>
                    <mat-select #orgTypeSelect (change)="orgTypeChanged(orgTypeSelect.value)" placeholder="{{resources.Account.OrganizationTypeSelect}}" [(value)]="organization.type.id" >
                        <mat-option *ngFor="let orgType of organizationTypes" [value]="orgType.id">{{ orgType.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button class="org_type_button" color="primary" (click)="openManageOrganizationTypeModal()">{{resources.Account.ManageOrganizationType}}</button>
                <br>
                <mat-form-field appearance="fill">
                    <mat-label>{{resources.Organization.EmailType}}</mat-label>
                    <mat-icon matSuffix></mat-icon>
                    <mat-select [required]="isEmailTypeRequired" #emailTypeSelect formControlName="emailType" (change)="emailTypeChanged(emailTypeSelect.value)" placeholder="{{resources.Account.EmailTypeSelect}}" [(value)]="selectedEmailType">
                        <mat-option *ngFor="let type of emailTypes" [value]="type">{{ type.value }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="isEmailTypeRequired">{{getErrorMessage('emailType')}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>{{resources.Account.Email}}</mat-label>
                    <div class="input-emails">
                        <input matInput [(ngModel)]="emailToAdd" [required]="isEmailRequired" formControlName="email">
                    </div>
                    <mat-error *ngIf="!isFieldValid('email')">{{getErrorMessage('email')}}</mat-error>
                </mat-form-field>
                <button  class="email-button" type="button" mat-raised-button color="primary" [disabled]="isEmailAddingDisabled" cdkFocusInitial (click)="addEmail(emailToAdd)">
                    <mat-icon matSuffix>email</mat-icon>
                    {{resources.Common.Add}} {{resources.Account.Email}}
                </button>
                <mat-form-field class="email-list" *ngIf="this.organization.emailInformations != null && this.organization.emailInformations.length > 0">
                    <mat-label>Existing emails</mat-label>
                    <mat-chip-list [multiple]="true">
                        <mat-chip *ngFor="let emailInfo of this.organization.emailInformations" [color]="undefined" class="existing-emails"
                                  removable (removed)="removeEmail(emailInfo.email)">
                                  <div class="email-info">
                                    <span class="label">{{emailInfo.type}}</span>
                                    <span>{{emailInfo.email}}</span>
                                  </div>
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <mat-icon matSuffix></mat-icon>
                </mat-form-field>
                <br>
                <mat-form-field appearance="fill">
                    <mat-label>{{resources.Organization.StreetAddress}}</mat-label>
                    <input matInput [(ngModel)]="organization.streetAddress" formControlName="streetAddress" required>
                    <mat-icon matSuffix></mat-icon>
                    <mat-error *ngIf="!isFieldValid('streetAddress')">{{getErrorMessage('streetAddress')}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>{{resources.Organization.ZipCode}}</mat-label>
                    <input matInput [(ngModel)]="organization.zipCode" formControlName="zipCode" required>
                    <mat-icon matSuffix></mat-icon>
                    <mat-error *ngIf="!isFieldValid('zipCode')">{{getErrorMessage('zipCode')}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>{{resources.Organization.City}}</mat-label>
                    <input matInput [(ngModel)]="organization.city" formControlName="city" required>
                    <mat-icon matSuffix></mat-icon>
                    <mat-error *ngIf="!isFieldValid('city')">{{getErrorMessage('city')}}</mat-error>
                </mat-form-field>
                <br>
                <mat-form-field appearance="fill">
                    <mat-label>{{resources.Organization.Region}}</mat-label>
                    <input matInput [(ngModel)]="organization.region" formControlName="region" required>
                    <mat-icon matSuffix></mat-icon>
                    <mat-error *ngIf="!isFieldValid('region')">{{getErrorMessage('region')}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>{{resources.Organization.Country}}</mat-label>
                    <input matInput [(ngModel)]="organization.country" formControlName="country" required>
                    <mat-icon matSuffix></mat-icon>
                    <mat-error *ngIf="!isFieldValid('country')">{{getErrorMessage('country')}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>{{resources.Organization.State}}</mat-label>
                    <input matInput [(ngModel)]="organization.state" formControlName="state">
                    <mat-icon matSuffix></mat-icon>
                    <mat-error *ngIf="!isFieldValid('state')">{{getErrorMessage('state')}}</mat-error>
                </mat-form-field>
                <br>
                <br>
                <mat-form-field appearance="fill">
                    <mat-label>{{resources.Organization.AuthenticationUrl}}</mat-label>
                    <input matInput [(ngModel)]="organization.authenticationUrl" formControlName="authenticationUrl">
                    <mat-icon matSuffix></mat-icon>
                    <mat-error *ngIf="!isFieldValid('authenticationUrl')">{{getErrorMessage('authenticationUrl')}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>{{resources.Organization.ServiceUrl}}</mat-label>
                    <input matInput [(ngModel)]="organization.serviceUrl" formControlName="serviceUrl">
                    <mat-icon matSuffix></mat-icon>
                    <mat-error *ngIf="!isFieldValid('serviceUrl')">{{getErrorMessage('serviceUrl')}}</mat-error>
                </mat-form-field>
                <br>
                <label>
                    {{resources.Organization.CanSendRequest}}:
                    <input type="checkbox" [checked]="organization.canSendRequest" (change)="toggleCheckboxValue('canSendRequest')">
                </label><br />
                <label>
                    {{resources.Organization.CanReceiveRequest}}:
                    <input type="checkbox" [checked]="organization.canReceiveRequest" (change)="toggleCheckboxValue('canReceiveRequest')">
                </label><br />
                <br>
            </form>
        </div>
    </div>
    <div>
        <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="updateOrganization()">{{resources.Common.Save}}</button>
    </div>
</div>
