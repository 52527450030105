import { Component, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import { BaseComponent } from "./base.component";

@Component({
    selector: "chart-component",
    templateUrl: "./chart.component.html",
    styleUrls: ["./chart.component.scss"]
})

export class ChartComponent extends BaseComponent {

    @Input()
    set series(series: any) {
        this.chartOptions.series = series;
        this.updateFlag = true;
    }
    @Input()
    set xAxis(xAxis: any) {
        this.chartOptions.xAxis = {
            categories: xAxis
        };
    }

    updateFlag = false;
    highChart: typeof Highcharts = Highcharts;
    chartOptions: Highcharts.Options = {

        title: {
            text: this.resources.Organization.RequestPerHour

        },
        yAxis: {
            title: {
                text: this.resources.Organization.Requests
            }
        },
        xAxis: {
            categories: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: false
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            type: "line",
            data: [],
            name: "no data"
        }]
    };
}
