<div class="container" *ngIf="totalItems > 0">
    <div>
        <button mat-icon-button color="primary" *ngIf="totalItems > itemsPerPage" (click)="changePage(Direction.Previous)" [disabled]="currentPage === 1">
            <mat-icon>skip_previous</mat-icon>
        </button>

         <div class="row justify-content-center content">
            <div class="col-sm-12 col-md-6 col-lg-3" *ngFor="let organization of organizations">
                <organization-summary
                    class=" summary"
                    [organization]="organization"
                    [@slideInOut]="{ value: '', params: { enterDirection: enterDirection, leaveDirection: leaveDirection } }"                                  
                ></organization-summary>
            </div>
         </div>
        
        <button mat-icon-button *ngIf="totalItems > itemsPerPage" color="primary" (click)="changePage(Direction.Next)" [disabled]="currentPage >= totalPages()">
            <mat-icon>skip_next</mat-icon>
        </button>
    </div>
    <mat-progress-bar *ngIf="totalItems > itemsPerPage" class="progress-bar" mode="determinate" value="{{progressValue()}}"></mat-progress-bar>
</div>
