import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { AuthorizeService, IUser } from "../../api-authorization/authorize.service";
import { UserAccessService } from "../services/user-access.service";

@Component({
    selector: "app-nav-menu",
    templateUrl: "./nav-menu.component.html",
    styleUrls: ["./nav-menu.component.scss"]
})
export class NavMenuComponent implements OnInit {
    isExpanded = false;
    isAuthenticated: Observable<boolean>;
    user?: IUser ;

    constructor(authService: AuthorizeService, private readonly userAccessService: UserAccessService) {
        this.isAuthenticated = authService.isAuthenticated$;
    }

   ngOnInit() {
        this.userAccessService.userInitialized.pipe(first( x  => x != null)).subscribe((user?: IUser) => {
            this.user = user;
        });
    }

    get isAdmin() {
        return this.userAccessService.isAdmin();
    }

    get isSystemAdmin() {
        return this.userAccessService.isSystemAdmin();
    }

    collapse() {
        this.isExpanded = false;
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }
}
