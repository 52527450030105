import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import { UserAccessService } from "app/services/user-access.service";

@Injectable({
    providedIn: "root"
})
export class OrganizationGuard implements CanActivate {
    constructor(private router: Router, private userAccessService: UserAccessService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (this.userAccessService.isSystemAdmin()) {
            return true;
        }

        const organizationId = route.params["organizationId"];
        const isHaveAccess = this.userAccessService.haveAccessToOrganization(organizationId);

        if (isHaveAccess) {
            return true;
        } else {
            this.router.navigate(["/forbidden"], { skipLocationChange: true });
            return false;
        }
    }
}
