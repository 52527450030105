import { Injectable } from "@angular/core";

import { ApiService } from "../services/api.service";

import { ILogStatistic } from "../../interfaces/api-models";

@Injectable()
export class StatisticsApiService extends ApiService {

    getStatistics(from: Date, to: Date, organizationIds: string[]) {
        const organizationIdsQueryParam = this.convertOrganizationIdsToQueryString(organizationIds);
        return this.get<ILogStatistic[]>(`statistics?${organizationIdsQueryParam}&from=${from.toUTCString()}&to=${to.toUTCString()}`).toPromise();
    }

    private convertOrganizationIdsToQueryString(organizationIds: string[]) {
        const params = [];

        for (const id of organizationIds) {
            params.push("organizationIds=" + id);
        }

        return params.join("&");
    }
}