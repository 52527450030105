<div class="container">
    <mat-form-field appearance="standard">
        <mat-label>{{resources.Account.Organizations}}</mat-label>
        <mat-hint *ngIf="selectedOrganization.length === 0">{{resources.Statistics.SelectOrganization}}</mat-hint>
    
        <mat-select 
            [(ngModel)]="selectedOrganization" 
            (ngModelChange)="reinitCharts()"
            name="organizations" 
            required
        >
            <mat-option *ngFor="let option of organizations" [value]="option.id">{{option.name}}</mat-option>
        </mat-select>
    </mat-form-field>
     <mat-form-field appearance="standard">
        <mat-label>{{resources.Statistics.Perspective}}</mat-label>
        <mat-select 
            [(ngModel)]="selectedPerspective" 
            (ngModelChange)="localUpdateCharts()"
            name="perspective" 
        >    
            <mat-option *ngFor="let option of perspectives" [value]="option.key">{{option.value}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="standard">
        <mat-label>{{resources.Statistics.DateRange}}</mat-label>
        <mat-select 
            [(ngModel)]="selectedDateRange" 
            (ngModelChange)="updateRangeType()"
            name="date-range" 
        >    
            <mat-option *ngFor="let option of dateRanges" [value]="option.key">{{option.value}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="standard">
        <mat-label>{{resources.Statistics.LogAggregation}}</mat-label>
        <mat-select 
            [(ngModel)]="selectedAggregation" 
            (ngModelChange)="localUpdateCharts()"
            name="aggregation" 
        >    
            <mat-option 
                *ngFor="let option of aggregations" 
                [value]="option.key"
                [disabled]="isAggregationTypeDisabled(option.key)"
            >
                {{option.value}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="standard">
        <mat-label>{{resources.Statistics.LogStatus}}</mat-label>
        <mat-select 
            [(ngModel)]="selectedStatus" 
            (ngModelChange)="localUpdateCharts()"
            name="statuses" 
        >    
            <mat-option *ngFor="let option of statuses" [value]="option.key">{{option.value}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="standard">
        <mat-label>{{resources.Log.MessageType}} {{reportService.getSelectedCount(selectedMessageTypes)}}</mat-label>
        <mat-select [formControl]="selectMessageTypes"
            [(ngModel)]="selectedMessageTypes" 
            (ngModelChange)="localUpdateCharts()"
            name="message-types"
            multiple 
        >    
        
            <app-select-all-option [control]="selectMessageTypes" [values]="messageTypes"></app-select-all-option>
            <mat-divider></mat-divider>
            <mat-option *ngFor="let option of messageTypes" [value]="option.key">{{option.value}}</mat-option>
        </mat-select>
    </mat-form-field>
   
    <div class="chart" *ngIf="dataRecieved">
        <highcharts-chart 
            [Highcharts]="requestChart"
            [options]="requestChartOptions"
            [(update)]="updateFlag"
            [oneToOne]="true"
        >
        </highcharts-chart>
        <highcharts-chart 
            [Highcharts]="requestPieChart"
            [options]="requestPieChartOptions"
            [(update)]="updateFlag"
            [oneToOne]="true"
        >
        </highcharts-chart>
    </div>
</div>